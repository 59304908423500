import { toast } from 'react-toastify';
const AddStockValidation = {
    validFormData (formData,propsdata){
        let errors = {};
        let formIsValid = true;
        var numbers = /^[0-9]+$/;
        if (!formData['make_month']) {
            formIsValid = false;
            errors["make_month"] = propsdata.t('addInventory.validation_error.make_month_is_required');
        }
        if (!formData['make_year']) {
            formIsValid = false;
            errors["make_year"] = propsdata.t('addInventory.validation_error.make_year_is_required');
        }
        if (!formData['version_id']) {
            formIsValid = false;
            errors["version_id"] = propsdata.t('addInventory.validation_error.Car_version_is_required');
        }
        if (formData['is_registered_car'] && formData['is_registered_car'] == "1") {
            if (!formData['reg_no']) {
                formIsValid = false;
                errors["reg_no"] = propsdata.t('addInventory.validation_error.Registration_number_is_required');
            }
            
            if (formData['reg_no'] && (formData['reg_no'].length < 3 || formData['reg_no'].length > 9)) {
                formIsValid = false;
                errors["reg_no"] = propsdata.t('addInventory.validation_error.registration_number_length_validation');
            }

            // if (!formData['reg_month']) {
            //     formIsValid = false;
            //     errors["reg_month"] = propsdata.t('addInventory.validation_error.Registration_month_is_required');
            // }
            // if (!formData['reg_year']) {
            //     formIsValid = false;
            //     errors["reg_year"] = propsdata.t('addInventory.validation_error.Registration_year_is_required');
            // }
            
            if(formData['reg_month'] && formData['reg_year'] && formData['make_month'] && formData['make_year']){
                if(formData['reg_year'] == formData['make_year'] && formData['reg_month'] < formData['make_month']){
                    formIsValid = false;
                    errors["reg_month"] = propsdata.t('addInventory.validation_error.reg_month_greater');                    
                }
                else if(formData['make_year'] > formData['reg_year']){
                    formIsValid = false;
                    errors["reg_year"] = propsdata.t('addInventory.validation_error.reg_year_smaller')                    
                }
            }

            // if (!formData['reg_valid_date']) {
            //     formIsValid = false;
            //     errors["reg_valid_date"] = propsdata.t('addInventory.validation_error.no_plate_valid_req');
            // }

            // if (!formData['reg_valid_month']) {
            //     formIsValid = false;
            //     errors["reg_valid_month"] = "Number plate valid month is required.";
            // }
            // if (!formData['reg_valid_year']) {
            //     formIsValid = false;
            //     errors["reg_valid_year"] = "Number plate valid year is required.";
            // }

            let reg_make_month = formData['reg_month'] || formData['make_month'];
            let reg_make_year =  (formData['reg_month'])? formData['reg_year'] : formData['make_year'];

            if(reg_make_month && reg_make_year && formData['reg_valid_month'] && formData['reg_valid_year']){
                if(reg_make_year == formData['reg_valid_year'] && reg_make_month > formData['reg_valid_month']){
                    formIsValid = false;
                    errors["reg_valid_month"] = propsdata.t('addInventory.validation_error.no_plate_valid_month');
                }
                else if(reg_make_year > formData['reg_valid_year']){
                    formIsValid = false;
                    errors["reg_valid_year"] = propsdata.t('addInventory.validation_error.no_plate_valid_year');
                }
            }

            if (!formData['reg_place_city_id']) {
                formIsValid = false;
                errors["reg_place_city_id"] = propsdata.t('addInventory.validation_error.Registration_city_is_required');
            }
        }

        if (!formData['km_driven']) {
            formIsValid = false;
            errors["km_driven"] = propsdata.t('addInventory.validation_error.Car_driven_km_is_required');
        } else if ((formData['km_driven'].toString()).match(numbers) == null) {
            formIsValid = false;
            errors["km_driven"] = propsdata.t('addInventory.validation_error.Car_driven_should_numeric_characters_only');
        }
        else if (formData['km_driven'] < 1000 || formData['km_driven'] > 1000000) {
            formIsValid = false;
            errors["km_driven"] = propsdata.t('addInventory.validation_error.Car_driven_should_less_than');
        }
        if (!formData['uc_colour_id'] && !formData['other_colour']) {
            formIsValid = false;
            errors["uc_colour_id"] = propsdata.t('addInventory.validation_error.Car_colour_is_required');
        }
        if (!formData['owner_type']) {
            formIsValid = false;
            errors["owner_type"] = propsdata.t('addInventory.validation_error.Owner_type_is_required');
        }
        if (!formData['insurance_type']) {
            formIsValid = false;
            errors["insurance_type"] = propsdata.t('addInventory.validation_error.Insurance_type_is_required');
        }
        if (!formData['car_price']) {
            formIsValid = false;
            errors["car_price"] = propsdata.t('addInventory.validation_error.Car_price_is_required');
        } else if (formData['car_price'].toString().match(numbers) == null) {
            formIsValid = false;
            errors["car_price"] = propsdata.t('addInventory.validation_error.Car_price_should_numeric_characters_only');
        }
        // if (!formData['reg_valid_year']) {
        //     formIsValid = false;
        //     errors["reg_valid_year"] = "Number plate valid year is required.";
        // }
        if (!formIsValid) {
            toast.error(propsdata.t('addInventory.validation_error.fill_required_fields'));
        }
        let returnData={formIsValid:formIsValid,errors:errors};
        return returnData;
    }
    }
    export default AddStockValidation;