import React from 'react';
import ReactDOM from 'react-dom';
// import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { BrowserRouter } from 'react-router-dom';
import reducer from './store/reducer/reducer';
import { createStore } from 'redux';
import { Provider } from 'react-redux';
import { I18nextProvider } from 'react-i18next';
import i18next from 'i18next';
// import common_ph from "./translations_ph/en/common.json";
import common_id from "./translations/id/common.json";
import common_en from "./translations/en/common.json";
import inventory_id from "./translations/id/inventory.json";
import inventory_en from "./translations/en/inventory.json";
import dealer_id from "./translations/id/dealer.json";
import dealer_en from "./translations/en/dealer.json";
import lead_id from "./translations/id/lead.json";
import lead_en from "./translations/en/lead.json";
import secureStorage from './config/encrypt';

i18next.init({
    interpolation: { escapeValue: false },
    lng: (secureStorage.getItem('lang') == 'id')? 'id': 'en',
    resources: {
        en: {
            common: common_en,
            inventory: inventory_en,
            dealers:dealer_en,
            lead: lead_en,
            },
        id: {
            common: common_id,
            inventory: inventory_id,
            dealers:dealer_id,
            lead: lead_id,

        },
    },
});


const store = createStore(reducer, window.devToolsExtension && window.devToolsExtension());

ReactDOM.render(<I18nextProvider i18n={i18next}><Provider store={store}><BrowserRouter><App /></BrowserRouter></Provider></I18nextProvider>, document.getElementById('root'));
serviceWorker.unregister();
