import React, { Component } from 'react';
import OtpLogin from './otp-login';
import ForgotPassword from './ForgotPassword';
import InputField from '../../elements/InputField';
import { withTranslation } from 'react-i18next';
class LoginForm extends Component {
    constructor(props) {
        super(props);
        this.state = {
            displayForm: 'email',
            fields: {},
            errors: {}
        }
    }
    componentDidMount = ()=>{

    }

    componentWillReceiveProps = (nextProps)=>{
        this.setState({errors : nextProps.errorMessage});
    }
    submitLoginForm = (event) =>{
        if (typeof this.props.onClick === 'function') {
            this.props.onClick(event);
        }
    }

    handleChange = event =>{
        if (typeof this.props.onChange === 'function') {
            this.props.onChange(event);
        }
    }

    showLoginForm = event => {
        let showingSection = event.currentTarget.dataset.key;
        this.setState({
            displayForm: showingSection
        })
    }
    render() {
        const {fields, errors} = this.state;
        return (
            <div className="item active">
                <div className="sm">
                    <div id="fedupDix" className="row os-animation animated fadeInUp" data-os-animation="fadeInUp" data-os-animation-delay="0.50s">
                        <div className="col-md-12" id="modified_design">
                            <div className="signUp-container text-left">
                                <div className={(this.state.displayForm === 'forgot' ? "hide" : " ")}>
                                    <h1 className="login-h1">{this.props.t('login.login')}</h1>
                                    <p className="login-p">{this.props.t('login.login_title')}</p>
                                    <div className="mrg-b30">
                                        <button onClick={this.showLoginForm} className={"btn btn-default btn-lg button-rounded mrg-L15 " + (this.state.displayForm === 'mobile' ? "active" : " ")} type="button" id="sign_upBtn" data-key="mobile" >{this.props.t('login.by_mobile')}</button>
                                        <button onClick={this.showLoginForm} className={"btn btn-default btn-lg button-rounded " + (this.state.displayForm === 'email' ? "active" : " ")} type="button" id="sign_inBtn" data-key="email">{this.props.t('login.by_email')}</button>
                                    </div>
                                </div>
                                {this.state.displayForm === 'email' ?
                                    <form className="text-left os-animation animated fadeInDown" data-os-animation="fadeInDown" data-os-animation-delay="0s" method="post" id="loginform" onSubmit={this.submitLoginForm}>
                                        <div className="row">
                                            <div className="col-sm-12">
                                                <InputField
                                                    inputProps={{
                                                        id: "email",
                                                        type: "text",
                                                        name: "email",
                                                        label:this.props.t('joinus.email'),
                                                        dataerror: errors.email
                                                    }}
                                                    onChange={this.handleChange}
                                                />
                                            </div>
                                            <div className="col-sm-12">
                                                <InputField
                                                    inputProps={{
                                                        id: "password",
                                                        type: "password",
                                                        name: "password",
                                                        label:this.props.t('login.password'),
                                                        dataerror: errors.password
                                                    }}
                                                    onChange={this.handleChange}
                                                />
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-sm-6">
                                                {/*<InputField 
                                                    inputProps={{
                                                        id: "remember",
                                                        type: "checkbox",
                                                        name: "remember",
                                                        label:this.props.t('login.remember_me')
                                                    }}
                                                    onChange={this.handleChange}
                                                /> */}
                                            </div>
                                            <div className="col-sm-6"><label className="mrg-B0 forgotP" id="forgotPassword"><a onClick={this.showLoginForm} data-key="forgot">{this.props.t('login.forgot_password')}</a></label></div>
                                        </div>
                                        <span id="invalidLogin" className="invalid-login"></span>
                                        <div className="form-group">
                                            <button className="btn btn-primary btn-lg btn-block" id="sub-login" name="email_login" type="submit" onClick={this.submitLoginForm}>{this.props.t('login.sign_in')}</button>
                                        </div>
                                    </form>
                                    :
                                    this.state.displayForm === 'forgot' ?
                                    <ForgotPassword showLoginForm={this.showLoginForm} />
                                    :
                                    <OtpLogin />
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default withTranslation('common')(LoginForm);