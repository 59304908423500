import React, { Component } from 'react';
import { Redirect, NavLink } from 'react-router-dom';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Select from 'react-select';
import Button from './../../elements/Button';
import InputField from './../../elements/Input';
import CheckBox from './../../elements/CheckBox';
import Accordion from './../../elements/Accordion';
import { toast } from 'react-toastify';
import { withTranslation } from 'react-i18next';
import { Map, InfoWindow, Marker, GoogleApiWrapper } from 'google-maps-react';
import { connect } from 'react-redux';
import { GOOGLE_MAP_API_KEY, MOBILE_VALIDATION } from './../../../config/constant';
import secureStorage from './../../../config/encrypt';
const style = {
    width: '94%',
    height: '600px'
}

class OutletDetails extends Component {
    constructor(props) {
        super(props);
        this.defaultoutlet = { "opening_timings": { "Monday": { "open": "10:00 AM", "close": "7:00 PM", "on": "on" }, "Tuesday": { "open": "10:00 AM", "close": "7:00 PM", "on": "on" }, "Wednesday": { "open": "10:00 AM", "close": "7:00 PM", "on": "on" }, "Thursday": { "open": "10:00 AM", "close": "7:00 PM", "on": "on" }, "Friday": { "open": "10:00 AM", "close": "7:00 PM", "on": "on" }, "Saturday": { "open": "10:00 AM", "close": "7:00 PM", "on": "on" }, "Sunday": { "open": "10:00 AM", "close": "7:00 PM", "on": "on" } } }
        this.state = {
            loading: true,
            key: 1,
            city_id: "",
            state_id: "",
            locality_id: "",
            startDate: new Date(),
            basic_details: {},
            errors: {},
            responseMsg: null,
            permission: props.permission || {},
            outlet_list: props.outlet_list || [],
            cityAllList: props.cityAllList || [],
            // filteredcity:{},
            outlet_errors: []
        };


    }

    componentDidMount = async () => {
        console.log('did mount');
        // console.log(this.state.outlet_list);
        if (this.state.outlet_list.length == 0 && this.props.outlet_list.length > 0) {
            //  console.log('No state');
            let outlet_list = this.props.outlet_list;

            if (this.state.cityAllList.length == 0) {
                let StateList = this.props.StateList;
                let cityAllList = this.props.cityAllList;
                let localityList = this.props.localityList || [];
                this.setState({ StateList: StateList, cityAllList: cityAllList, localityList: localityList });
            }

            this.setState({ outlet_list: outlet_list, loading: false }, () => {
                // console.log('state change');

                this.state.outlet_list.forEach((val, index) => {
                    if (val.state_id > 0) {
                        // console.log('state change state id =>'+val.state_id);
                        this.handleStateOptionChange(index)(val.state_id);
                        this.handleCityOptionChange(index)(val.city_id);
                    }

                })

            })



        } else {

            if (this.state.cityAllList.length == 0) {
                let StateList = this.props.StateList;
                let cityAllList = this.props.cityAllList;
                let localityList = this.props.localityList || [];
                this.setState({ StateList: StateList, cityAllList: cityAllList, localityList: localityList });
            }

            this.state.outlet_list.forEach((val, index) => {
                if (val.state_id > 0) {
                    // console.log('else part state change state id =>'+val.state_id);
                    this.handleStateOptionChange(index)(val.state_id);
                    this.handleCityOptionChange(index)(val.city_id);
                }

            })

            this.setState({ loading: false })
        }


    }

    handleInputchange = (idx) => event => {
        let inpname = event.target.name;
        let value = event.target.value;
        this.state.outlet_list[idx][inpname] = value;

        var error = {};
        //var outlet_errors = this.state.outlet_errors;

        if (inpname == 'address2') {
            let address1 = this.state.outlet_list[idx].address;
            if (address1) {
                let address1arr = address1.split('|');
                this.state.outlet_list[idx].address = address1arr[0];
            }
        }

        if (!value) {
            if(inpname != "website_url")
            error[inpname] = this.props.t('dealerDetails.Basic_Details_master.validation_texts.please_enter') + inpname.replace("_", " ");
        }
        else {

            if (inpname == 'latitude' || inpname == 'longitude') {

                if (isNaN(value)) {

                    error[inpname] = this.props.t('dealerDetails.Outlet_Details_master.validation_texts.invalid') + inpname;
                }
            } else if (inpname == 'contact_number') {
                if (!MOBILE_VALIDATION.test(value)) {
                    error[inpname] = this.props.t('dealerDetails.Basic_Details_master.validation_texts.enter_valid_phone')
                }

            } else if (inpname == 'Monday_open' || inpname == 'Monday_close' || inpname == 'Tuesday_open' || inpname == 'Tuesday_close' || inpname == 'Wednesday_open' || inpname == 'Wednesday_close' || inpname == 'Thursday_open' || inpname == 'Thursday_close'
                || inpname == 'Friday_open' || inpname == 'Friday_close' || inpname == 'Saturday_open' || inpname == 'Saturday_close' || inpname == 'Sunday_open' || inpname == 'Sunday_close') {
                var isValid = /^(1[0-2]|0?[1-9]):([0-5][0-9])?$/.test(value.replace(' PM', '').replace(' AM', ''));
                if (!isValid) {
                    error[inpname] = this.props.t('dealerDetails.Outlet_Details_master.validation_texts.invalid') + inpname.replace('_', ' ');
                }

            }
        }



        if (inpname == 'Monday_open') this.state.outlet_list[idx].opening_timings.Monday.open = value;
        if (inpname == 'Monday_close') this.state.outlet_list[idx].opening_timings.Monday.close = value;

        if (inpname == 'Tuesday_open') this.state.outlet_list[idx].opening_timings.Tuesday.open = value;
        if (inpname == 'Tuesday_close') this.state.outlet_list[idx].opening_timings.Tuesday.close = value;

        if (inpname == 'Wednesday_open') this.state.outlet_list[idx].opening_timings.Wednesday.open = value;
        if (inpname == 'Wednesday_close') this.state.outlet_list[idx].opening_timings.Wednesday.close = value;

        if (inpname == 'Thursday_open') this.state.outlet_list[idx].opening_timings.Thursday.open = value;
        if (inpname == 'Thursday_close') this.state.outlet_list[idx].opening_timings.Thursday.close = value;

        if (inpname == 'Friday_open') this.state.outlet_list[idx].opening_timings.Friday.open = value;
        if (inpname == 'Friday_close') this.state.outlet_list[idx].opening_timings.Friday.close = value;

        if (inpname == 'Saturday_open') this.state.outlet_list[idx].opening_timings.Saturday.open = value;
        if (inpname == 'Saturday_close') this.state.outlet_list[idx].opening_timings.Saturday.close = value;

        if (inpname == 'Sunday_open') this.state.outlet_list[idx].opening_timings.Sunday.open = value;
        if (inpname == 'Sunday_close') this.state.outlet_list[idx].opening_timings.Sunday.close = value;

        // outlet_errors[idx] = error;
        //console.log(this.state.outlet_list[idx]);
        //let newoutlet = this.state.outlet_list;

        this.state.outlet_list[idx].error = error;
        let outlet_list = this.state.outlet_list;
        this.setState({ outlet_list: outlet_list }, () => {
            //    console.log('state change on change of input');
        })
        //    console.log(this.state.outlet_list[idx]);
    }


    addOutlet = () => {
        //alert(this.props.dealer_id);
        //this.state.outlet_errors[0]={}
        let newoutlet = this.state.outlet_list;
        if (this.props.dealer_id && this.props.dealer_id > 0) {
            this.defaultoutlet.dealer_id = this.props.dealer_id;
        }
        let newitemtopush = Object.assign({}, this.defaultoutlet);
        newitemtopush.id = 'new' + newoutlet.length;
        newoutlet.unshift(newitemtopush);
        this.setState({ outlet_list: newoutlet })
        //    console.log(this.state.outlet_list);
    }

    deleteOutlet = (idx) => {
        var del_confirm = window.confirm(this.props.t('dealerDetails.Outlet_Details_master.validation_texts.are_you_sure'));
        if (del_confirm) {
            this.state.outlet_list.splice(idx, 1);
            let newoutlet = this.state.outlet_list;
            this.setState({ outlet_list: newoutlet });
        }

    }

    ChangeCheckbox = (idx, field_name) => (event) => {

        let typelistnew = this.state.outlet_list;
        let currtype = typelistnew[idx].type;
        let currtypearray = [];
        if (currtype) {
            currtypearray = currtype.split(',');

        }
        if (field_name == 'showroom' || field_name == 'workshop') {

            let currval = event.target.value;
            if (event.target.checked) {

                if (!currtypearray.includes(currval)) {
                    currtypearray.push(currval);
                }

            } else {

                if (currtypearray.includes(currval)) {
                    var index = currtypearray.indexOf(currval);
                    if (index !== -1) currtypearray.splice(index, 1);
                }
            }
            typelistnew[idx].type = currtypearray.join(',');
            // console.log(typelistnew[idx].type);
            //console.log(currtypearray.join(','));
        }
        else {
            if (event.target.checked) {
                typelistnew[idx][field_name] = event.target.value;
            } else {
                typelistnew[idx][field_name] = 0;
            }
        }
        this.setState({ outlet_list: typelistnew });
    }

    copyToClipboard = (idx) => {

        // console.log(idx);
        let add2copy = [];
        let current_outlet = this.state.outlet_list;
        let outlet = current_outlet[idx] || {};
        add2copy.push(outlet.name);
        let address = (outlet.address) ? outlet.address.replace("|", ", ") : "";
        add2copy.push(address);
        add2copy.push(
            (this.props.StateList || []).filter(stateinfo => stateinfo.id == outlet.state_id).map(single_state => single_state.name)[0]
        );
        add2copy.push(
            (this.state.cityAllList || []).filter(cityinfo => cityinfo.id == outlet.city_id).map(single_city => single_city.name)[0]
        );
        add2copy.push(
            (this.props.localityList || []).filter(cityinfo => cityinfo.id == outlet.locality_id).map(single_city => single_city.name)[0]
        );
        //add2copy.push(outlet.pincode);
        //console.log(add2copy, add2copy.filter(v => (""+v).trim() ));
        const notUndefined = anyValue => typeof anyValue !== 'undefined';
        add2copy = add2copy.filter(notUndefined => notUndefined !== undefined).join(", ")

        var textField = document.createElement('textarea')
        textField.innerText = add2copy;
        document.body.appendChild(textField)
        textField.select()
        document.execCommand('copy')
        textField.remove();
        toast.info(this.props.t('dealerDetails.Outlet_Details_master.validation_texts.copy_to_clipboard'));
    }

    stringifyFormData(fd) {
        const data = {};
        for (let key of fd.keys()) {
            data[key] = fd.get(key);
        }
        //console.log(JSON.stringify(data, null, 2));
        return JSON.parse(JSON.stringify(data, null, 2));
    }

    saveoutletDetail = (idx) => (event) => {
        event.preventDefault();
        var parentThis = this;
        const form = event.target;
        const data = new FormData(form);
        const formdata = this.stringifyFormData(data);
        var formIsValid = false;
        var error = {};
        // var outlet_errors = this.state.outlet_errors;

        // console.log(formdata);
        Object.keys(formdata)
            .forEach((key) => {
                //console.log(key); // alerts key 
                //console.log(formdata[key]); // alerts value
                if (key == 'name' || key == 'contact_number' || key == 'address' || key == 'state_id' || key == 'city_id' || key == 'locality_id'
                    || key == 'latitude' || key == 'longitude' || key == 'Monday_open' || key == 'Monday_close' || key == 'Tuesday_open' || key == 'Tuesday_close' || key == 'Wednesday_open' || key == 'Wednesday_close' || key == 'Thursday_open' || key == 'Thursday_close' || key == 'Friday_open' || key == 'Friday_close' || key == 'Saturday_open' || key == 'Saturday_close' || key == 'Sunday_open' || key == 'Sunday_close') {

                    let value = formdata[key];
                    //this.state.outlet_list[idx][key]=value;
                    //console.log(value);
                    if (!value) {


                        let outletlist12 = { ...parentThis.state.outlet_list }
                        outletlist12[idx][key] = value;

                        if (key == 'Monday_open') outletlist12[idx].opening_timings.Monday.open = value;
                        if (key == 'Monday_close') outletlist12[idx].opening_timings.Monday.close = value;

                        if (key == 'Tuesday_open') outletlist12[idx].opening_timings.Tuesday.open = value;
                        if (key == 'Tuesday_close') outletlist12[idx].opening_timings.Tuesday.close = value;

                        if (key == 'Wednesday_open') outletlist12[idx].opening_timings.Wednesday.open = value;
                        if (key == 'Wednesday_close') outletlist12[idx].opening_timings.Wednesday.close = value;

                        if (key == 'Thursday_open') outletlist12[idx].opening_timings.Thursday.open = value;
                        if (key == 'Thursday_close') outletlist12[idx].opening_timings.Thursday.close = value;

                        if (key == 'Friday_open') outletlist12[idx].opening_timings.Friday.open = value;
                        if (key == 'Friday_close') outletlist12[idx].opening_timings.Friday.close = value;

                        if (key == 'Saturday_open') outletlist12[idx].opening_timings.Saturday.open = value;
                        if (key == 'Saturday_close') outletlist12[idx].opening_timings.Saturday.close = value;

                        if (key == 'Sunday_open') outletlist12[idx].opening_timings.Sunday.open = value;
                        if (key == 'Sunday_close') outletlist12[idx].opening_timings.Sunday.close = value;

                        error[key] = this.props.t('dealerDetails.Basic_Details_master.validation_texts.please_enter') + key.replace("_", " ");

                    } else {

                        if (key == 'latitude' || key == 'longitude') {

                            if (isNaN(value)) {

                                error[key] = this.props.t('dealerDetails.Outlet_Details_master.validation_texts.invalid') + key;
                            }
                        } else if (key == 'contact_number') {
                            if (!MOBILE_VALIDATION.test(value)) {
                                error[key] = this.props.t('dealerDetails.Basic_Details_master.validation_texts.enter_valid_phone')
                            }

                        } else if (key == 'Monday_open' || key == 'Monday_close' || key == 'Tuesday_open' || key == 'Tuesday_close' || key == 'Wednesday_open' || key == 'Wednesday_close' || key == 'Thursday_open' || key == 'Thursday_close'
                            || key == 'Friday_open' || key == 'Friday_close' || key == 'Saturday_open' || key == 'Saturday_close' || key == 'Sunday_open' || key == 'Sunday_close') {
                            var isValid = /^(1[0-2]|0?[1-9]):([0-5][0-9])?$/.test(value.replace(' PM', '').replace(' AM', ''));
                            if (!isValid) {
                                error[key] = this.props.t('dealerDetails.Outlet_Details_master.validation_texts.invalid') + key.replace('_', ' ');
                            }

                        }

                    }

                }
            });

        this.state.outlet_list[idx].error = error;
        let outlet_list = this.state.outlet_list;
        // outlet_errors[idx] = error;
        this.setState({ outlet_list: outlet_list })

        if ((Object.keys(error).length) == 0) {
            formIsValid = true;
        }

        if (formIsValid) {
            if (typeof this.props.onClick === 'function') {
                this.props.onClick(event, idx, formdata);
            }
        } else {
            // event.preventDefault();
            toast.error(this.props.t('dealerDetails.Outlet_Details_master.validation_texts.Please_resolve_error'));
        }

        event.preventDefault();
    }


    handleChange({ target }) {
        if (target.checked) {
            target.removeAttribute('checked');
            target.parentNode.style.textDecoration = "";
        } else {
            target.setAttribute('checked', true);
            target.parentNode.style.textDecoration = "line-through";
        }
        //this.props.handleChange(target.checked, target.id);
    }

    handlechecked = event => {

        let value = event.target.value;
        let status = true;
        if (event.target.checked) {
            status = true;
        } else {
            status = false;
        }
        let classname = 'switch-btn' + value;
        let chkbox = document.querySelectorAll('.' + classname).length;
        //alert(classname +':'+chkbox);
        for (let i = 0; i < chkbox; i++) {
            let idname = value + '_onoff' + i;
            let elements = document.getElementById(idname);
            if (elements) {
                elements.checked = status;
            }

        }

    }


    handleStateOptionChange = (idx) => (ovalue) => {
        //    console.log('handlechange',idx,ovalue);
        //console.log(ovalue);
        let stateid = 0;
        if (typeof ovalue == 'number') {
            stateid = ovalue;
        } else {
            stateid = ovalue.id;
        }
        this.state.outlet_list[idx].state_id = stateid;
        let citylist = this.state.cityAllList;
        const result = citylist.filter(cityinfo => cityinfo.state_id == stateid);
        // let filteredcityinfo = this.state.filteredcity || {}
        // filteredcityinfo['_'+idx] = result;
        // console.log('filteredcityinfo', filteredcityinfo);
        if (this.state.outlet_list[idx].error && this.state.outlet_list[idx].error.state_id) {
            this.state.outlet_list[idx].error.state_id = '';
        }

        this.setState({ ['filteredcity_' + idx]: result }, () => {
            //  console.log('city changes','filteredcity_'+idx,result)
        })
    }

    handleCityOptionChange = (idx) => (ovalue) => {
        let cityid = 0;
        if (typeof ovalue == 'number') {
            cityid = ovalue;
        } else {
            cityid = ovalue.id;
        }
        this.state.outlet_list[idx].city_id = cityid;
        let localityList = this.props.localityList || [];
        const result = localityList.filter(cityinfo => cityinfo.city_id == cityid);
        if (this.state.outlet_list[idx].error && this.state.outlet_list[idx].error.city_id) {
            this.state.outlet_list[idx].error.city_id = '';
        }

        // this.setState({filterLocality:result,city_id:cityid})
        this.setState({ ['filteredlocality_' + idx]: result })
    }

    handlelocalityOptionChange = (idx) => (ovalue) => {
        this.state.outlet_list[idx].locality_id = ovalue.id;
        if (this.state.outlet_list[idx].error && this.state.outlet_list[idx].error.locality_id) {
            this.state.outlet_list[idx].error.locality_id = '';
        }
        this.setState({ locality_id: ovalue.id })
    }

    componentWillReceiveProps = (nextProps) => {
        this.setState({
            basic_details: nextProps.basic_details,
            responseMsg: nextProps.responseMsg,
            outlet_list: nextProps.outlet_list,
            cityAllList: nextProps.cityAllList,
            // filteredcity:nextProps.filteredcity,
            outlet_errors: nextProps.outlet_errors,
            permission: nextProps.permission || {},
            loading: nextProps.loading
        }, () => {
            if (this.state.outlet_list) {
                // console.log(this.state.outlet_list);               
                this.state.outlet_list.forEach((val, index) => {
                    if (val.state_id > 0) {
                        // console.log('state id =>'+val.state_id);
                        this.handleStateOptionChange(index)(val.state_id);
                        this.handleCityOptionChange(index)(val.city_id);
                    }

                })
            }
        });
    }

    render() {

        const { StateList, dealer_id } = this.props;
        const { cityAllList, filteredcity, filterLocality } = this.state;
        let basicinfo = this.state.basic_details;
        let outletlist = this.state.outlet_list;
        if (!outletlist) {
            outletlist = this.props.outlet_list;
        }
        // console.log(outletlist);
        let { outlet_errors } = this.state;
        let role;
        if (secureStorage.getItem('loginUserInfo')) {
            let loginResponse = secureStorage.getItem('loginUserInfo');
            if (loginResponse && loginResponse.data && loginResponse.data.user_data) {
                role = loginResponse.data.user_data.role;
            }
        }
        //console.log(role);//dealercentral admin

        return (

            <div className={(this.state.loading) ? 'loading' : ''}>

                <div className="row">
                    <div className="col-sm-6">
                        <h1>{this.props.t('dealerDetails.Outlet_Details_master.Outlet_Details')}<span className="f12">
                            {
                                (basicinfo.gcd_code) ? <span> ({basicinfo.organization} - {basicinfo.gcd_code})</span> : ''
                            }
                        </span>
                        </h1>
                    </div>
                    {
                        (!outletlist || outletlist.length == 0) ?
                            <div className="col-sm-6 text-right"><Button type="button" btnClass="btn btn-success" onClick={this.addOutlet}>{this.props.t('dealerDetails.Outlet_Details_master.add_outlet_btn')}</Button></div> : ''
                    }

                </div>

                <div className="card os-animation animated fadeInDown" data-os-animation="fadeInDown" data-os-animation-delay="0s">
                    <div className="card-body accrdian-bt0">

                        {
                            ((!outletlist || outletlist.length == 0) && !this.state.loading) ?
                                <div style={{ textAlign: "center", padding: "15px" }}>
                                    <a style={{ padding: "5px 15px", border: "dotted 1px", cursor: "pointer" }} onClick={this.addOutlet}>{this.props.t('dealerDetails.Outlet_Details_master.add_outlet_btn')}</a>
                                </div> : ''
                        }

                        {outletlist.map((outletinfo, idx) =>
                            <Accordion title={(outletinfo.name && outletinfo.outlet_code) ? outletinfo.name + ' (' + outletinfo.outlet_code + ')' : ''} active={(outletinfo.id && typeof outletinfo.id == 'number') ? "false" : "true"} key={`key_${outletinfo.id}_${idx}`}>

                                <form onSubmit={this.saveoutletDetail(idx)}>
                                    {
                                        (outletinfo.id && !isNaN(outletinfo.id)) ? <input type="hidden" name="id" value={outletinfo.id} /> : ''
                                    }

                                    <input type="hidden" name="locality_old" value={outletinfo.locality_id} />

                                    <div className="card-heading">
                                        <div className="row">
                                            <div className="col-sm-6"><h2 className="card-title">{(outletinfo.id && typeof outletinfo.id == 'number') ? outletinfo.name : this.props.t('dealerDetails.Outlet_Details_master.add_outlet_default_txt')}</h2></div>
                                            <div className="col-sm-6 text-right">
                                                <ul className="list-inline clearfix">
                                                    {/* <li className="btn btn-link mrg-r15">
                                        <label className="switch-btn" htmlFor={outletinfo.id+'is_primary_button'}>
                                            <input className="switch-btn " id={outletinfo.id+'is_primary_button'} value="1" name="is_primary_button" type="checkbox" defaultChecked={(outletinfo.is_primary=='1')?'checked':''} onClick={this.handleChange.bind(this)}  />
                                            <div className="slider round"></div>
                                            <span className="switch-label">{this.props.t('dealerDetails.Outlet_Details_master.Primary')}</span>
                                        </label>

                                    </li> */}
                                                    {
                                                        (outletinfo.address) ?

                                                            <li className="mrg-r15">
                                                                <Button type="button" btnClass="btn btn-link" onClick={this.copyToClipboard.bind(this, idx)}><i className="ic-content_copy" ></i> {this.props.t('dealerDetails.Outlet_Details_master.Copy_Address')}</Button>
                                                            </li>
                                                            : ''

                                                    }
                                                    {
                                                        (outletinfo.id && !isNaN(outletinfo.id)) ? <>
                                                            {/* <li>
                                            <Button type="button" btnClass="btn btn-link"><i className="ic-createmode_editedit"></i> {this.props.t('dealerDetails.Outlet_Details_master.Edit_Outlet')}</Button>
                                        </li> */}
                                                            {/* <li>
                                            <Button type="button" btnClass="btn btn-link"><i className="ic-add"></i></Button>
                                        </li> */}
                                                        </> : <><li>
                                                            <Button type="button" btnClass="btn btn-link red" onClick={this.deleteOutlet.bind(this, idx)}>{this.props.t('dealerDetails.Outlet_Details_master.Delete')}</Button>
                                                        </li></>
                                                    }

                                                </ul>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="card-body" >
                                        <div id="outleteditbox">
                                            <div className="row">
                                                <div className="col-sm-6">
                                                    <div className="row">
                                                        <div className="col-sm-6">
                                                            <InputField type="text" name="name" id="showroom_name" placeholder={this.props.t('dealerDetails.Outlet_Details_master.name_placeholder')} value={outletinfo.name} label={this.props.t('dealerDetails.Outlet_Details_master.name_label')} onChange={this.handleInputchange(idx)}
                                                                error={outletinfo.error && outletinfo.error.name} required={true} />

                                                        </div>
                                                        <div className="col-sm-6">
                                                            <InputField maxLength="12" type="tel" name="contact_number" id="contact_number" placeholder={this.props.t('dealerDetails.Outlet_Details_master.contact_number_placeholder')} value={outletinfo.contact_number} label={this.props.t('dealerDetails.Outlet_Details_master.contact_number_label')} onChange={this.handleInputchange(idx)} error={outletinfo.error && outletinfo.error.contact_number} required={true} />

                                                        </div>
                                                        {
                                                            (outletinfo.address == null) ? <>
                                                                <div className="col-sm-6">
                                                                    <InputField type="text" name="address" id="address1" placeholder={this.props.t('dealerDetails.Outlet_Details_master.Address_Line') + '1'} value={outletinfo.address} label={this.props.t('dealerDetails.Outlet_Details_master.Address_Line') + '1'} onChange={this.handleInputchange(idx)} required={true} error={outletinfo.error && outletinfo.error.address} />
                                                                </div>

                                                                <div className="col-sm-6">
                                                                    <InputField type="text" name="address2" id="address2" placeholder={this.props.t('dealerDetails.Outlet_Details_master.Address_Line') + '2'} value={outletinfo.address2} label={this.props.t('dealerDetails.Outlet_Details_master.Address_Line') + '2'} onChange={this.handleInputchange(idx)} />
                                                                </div></> :
                                                                outletinfo.address.split('|').map((address, index) =>

                                                                    <div className="col-sm-6" key={'add1-' + index}>
                                                                        <InputField type="text" name={(index == 0) ? 'address' : 'address2'} id={(index == 0) ? 'address1' : 'address2'} placeholder={this.props.t('dealerDetails.Outlet_Details_master.Address_Line') + (index + 1)} value={address} label={this.props.t('dealerDetails.Outlet_Details_master.Address_Line') + (index + 1)} onChange={this.handleInputchange(idx)} required={(index == 0) ? true : false} error={((index == 0) && (outletinfo.error && outletinfo.error.address) ? outletinfo.error.address : '')} />
                                                                    </div>
                                                                )
                                                        }

                                                        {
                                                            (outletinfo.address2 || ((outletinfo.address && outletinfo.address.split('|').length == 1))) ? <div className="col-sm-6">
                                                                <InputField type="text" name="address2" id="address2" placeholder={this.props.t('dealerDetails.Outlet_Details_master.Address_Line') + '2'} value={outletinfo.address2} label={this.props.t('dealerDetails.Outlet_Details_master.Address_Line') + '2'} onChange={this.handleInputchange(idx)} />
                                                            </div> : ''
                                                        }

                                                        <div className="col-sm-6 form-group" style={(this.state.permission.cityViewOnly == true) ? { pointerEvents: 'none' } : null}>
                                                            <label>{this.props.t('dealerDetails.Outlet_Details_master.State_label')}<span className="required" style={{ color: 'red' }}>*</span></label>
                                                            <Select
                                                                id="state_id"
                                                                onChange={this.handleStateOptionChange(idx)}
                                                                options={StateList}
                                                                name="state_id"
                                                                placeholder={this.props.t('dealerDetails.Outlet_Details_master.State_placeholder')}
                                                                value={StateList.filter(({ id }) => id == outletinfo.state_id)}
                                                                getOptionLabel={({ name }) => name}
                                                                getOptionValue={({ id }) => id}
                                                            />
                                                            {
                                                                (outletinfo.error && outletinfo.error.state_id) ? <span className="error show">{outletinfo.error.state_id}</span> : ''
                                                            }
                                                        </div>
                                                        <div className="col-sm-6 form-group" style={(this.state.permission.cityViewOnly == true) ? { pointerEvents: 'none' } : null}>
                                                            <label>{this.props.t('dealerDetails.Outlet_Details_master.City_label')}<span className="required" style={{ color: 'red' }}>*</span></label>
                                                            <Select
                                                                id="city_id"
                                                                onChange={this.handleCityOptionChange(idx)}
                                                                options={this.state['filteredcity_' + idx] || []}
                                                                name="city_id"
                                                                placeholder={this.props.t('dealerDetails.Outlet_Details_master.City_placeholder')}
                                                                value={(this.state['filteredcity_' + idx] || []).filter(v => v.id == outletinfo.city_id)}
                                                                getOptionLabel={({ name }) => name}
                                                                getOptionValue={({ id }) => id}
                                                            />
                                                            {
                                                                (outletinfo.error && outletinfo.error.city_id) ? <span className="error show">{outletinfo.error.city_id}</span> : ''
                                                            }
                                                        </div>
                                                        <div className="col-sm-6 form-group" style={(this.state.permission.cityViewOnly == true) ? { pointerEvents: 'none' } : null}>
                                                            <label>{this.props.t('dealerDetails.Outlet_Details_master.Location_label')}<span className="required" style={{ color: 'red' }}>*</span></label>
                                                            <Select
                                                                id="locality_id"
                                                                onChange={this.handlelocalityOptionChange(idx)}
                                                                options={this.state['filteredlocality_' + idx] || []}
                                                                name="locality_id"
                                                                placeholder={this.props.t('dealerDetails.Outlet_Details_master.Location_placeholder')}
                                                                value={(this.state['filteredlocality_' + idx] || []).filter(v => v.id == outletinfo.locality_id)}
                                                                getOptionLabel={({ name, pin_code }) => name + (pin_code ? " - " + pin_code : "")}
                                                                getOptionValue={({ id }) => id}
                                                            />
                                                            {
                                                                (outletinfo.error && outletinfo.error.locality_id) ? <span className="error show">{outletinfo.error.locality_id}</span> : ''
                                                            }
                                                        </div>
                                                        {/* <div className="col-sm-6 form-group">
                                            <label>Front End City*</label>
                                            <Select
                                                id="city"
                                                value={this.state.city}
                                                
                                                options={city}
                                                name="city"
                                                placeholder="City"
                                                value={city.filter(({ value }) => value === this.state.city)}
                                                getOptionLabel={({ label }) => label}
                                                getOptionValue={({ value }) => value}
                                            />
                                        </div> */}
                                                        {/* <div className="col-sm-6">
                                            <InputField type="tel" name="pincode" id="pincode" placeholder={this.props.t('dealerDetails.Outlet_Details_master.pincode_placeholder')} value={outletinfo.pincode} label={this.props.t('dealerDetails.Outlet_Details_master.pincode_label')} onChange={this.handleInputchange(idx)} required={true} error={(outlet_errors && outlet_errors[idx] && outlet_errors[idx].pincode) ? outlet_errors[idx].pincode:''}/>
                                           
                                        </div> */}
                                                        <div className="col-sm-6">
                                                            <InputField type="text" name="latitude" id="Latitude" placeholder={this.props.t('dealerDetails.Outlet_Details_master.Latitude_placeholder')} value={outletinfo.latitude} label={this.props.t('dealerDetails.Outlet_Details_master.Latitude_label')} onChange={this.handleInputchange(idx)} required={true} error={outletinfo.error && outletinfo.error.latitude} readOnly={(outletinfo.is_lat_long_verified == 1)?'readOnly':''}/>

                                                        </div>
                                                        <div className="col-sm-6">
                                                            <InputField type="text" name="longitude" id="Longitude" placeholder={this.props.t('dealerDetails.Outlet_Details_master.Longitude_placeholder')} value={outletinfo.longitude} label={this.props.t('dealerDetails.Outlet_Details_master.Longitude_label')} onChange={this.handleInputchange(idx)} required={true} error={outletinfo.error && outletinfo.error.longitude} readOnly={( (outletinfo.is_lat_long_verified == 1))?'readOnly':''}/>

                                                        </div>
                                                        <div className="col-sm-6">
                                                            <InputField type="url" name="website_url" id="url" placeholder={this.props.t('dealerDetails.Outlet_Details_master.website_url_placeholder')} value={outletinfo.website_url} label={this.props.t('dealerDetails.Outlet_Details_master.website_url_label')} onChange={this.handleInputchange(idx)} error={outletinfo.error && outletinfo.error.website_url} />

                                                        </div>
                                                        {/* <div className="col-sm-6">
                                            <InputField type="text" name="pandacode" id="pandacode" placeholder="Panda Code" value="" label="Panda Code" />
                                        </div> */}
                                                        <div className="col-sm-12 ">
                                                            <h6>{this.props.t('dealerDetails.Outlet_Details_master.Outlet_Type_label')}<span className="required" style={{ color: 'red' }}>*</span></h6>
                                                            <div className="rc-inline">

                                                                <CheckBox type="checkbox" name="showroom" id="showroom_1" value="1" label={this.props.t('dealerDetails.Outlet_Details_master.Showroom')} checked={(outletinfo.type) ? (((outletinfo.type).split(',').includes('1')) ? true : false) : ''} onChange={this.ChangeCheckbox(idx, 'showroom')} />
                                                                <CheckBox type="checkbox" name="workshop" id="workshop_1" value="2" label={this.props.t('dealerDetails.Outlet_Details_master.Workshop')} checked={(outletinfo.type) ? (((outletinfo.type).split(',').includes('2')) ? true : false) : ''} onChange={this.ChangeCheckbox(idx, 'workshop')} />
                                                            </div>
                                                        </div>

                                                        <div className="col-sm-12 ">
                                                            <h6>{this.props.t('dealerDetails.Outlet_Details_master.Marked_as_label')}<span className="required" style={{ color: 'red' }}>*</span></h6>
                                                            <div className="rc-inline">

                                                                <CheckBox type="checkbox" name="is_primary" className="is_primary" id={'markas-primary' + idx} value="1" label={this.props.t('dealerDetails.Outlet_Details_master.Mark_as_Primary')} checked={(outletinfo.is_primary == 1) ? true : false} onChange={this.ChangeCheckbox(idx, 'is_primary')} />
                                                               {
                                                                   (role=='admin')?
                                                                   <CheckBox type="checkbox" name="is_lat_long_verified" id={'markas-latlong' + idx} value="1" label={this.props.t('dealerDetails.Outlet_Details_master.is_lat_long_verified_label')} checked={(outletinfo.is_lat_long_verified == 1) ? true : false} onChange={this.ChangeCheckbox(idx, 'is_lat_long_verified')}/>:''
                                                               } 
                                                               {(outletinfo.is_lat_long_verified == 1 && role=='dealercentral')?<label>{this.props.t('dealerDetails.Outlet_Details_master.lat_long_verified')}</label>:''}
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <h2 className="contentbreak">{this.props.t('dealerDetails.Outlet_Details_master.Outlet_Timing')}</h2>
                                                    {/* <div className="mrg-b30"><CheckBox type="checkbox" name={`alldaysopen${outletinfo.id}`} id={`alldaysopen${outletinfo.id}`} value={outletinfo.id} className={'alldaysopen_'+outletinfo.id} label={this.props.t('dealerDetails.Outlet_Details_master.Set_For_All_Days')} onChange={this.handlechecked}/></div> */}

                                                    <ul className="">
                                                        {

                                                            Object.keys(outletinfo.opening_timings).map((day, index) =>

                                                                <li key={index + '-day'}>
                                                                    <div className="row">
                                                                        <div className="col-lg-2 col-md-2">
                                                                            <p className="week-day">{day}</p>
                                                                        </div>
                                                                        <div className="col-lg-3 col-md-4 col-sm-6">
                                                                            <div className="form-group">
                                                                                {/* <label>{this.props.t('dealerDetails.Outlet_Details_master.Open_Time')}</label> */}
                                                                                {<InputField className="form-control" type="text" name={day + `_open`} placeholder={this.props.t('dealerDetails.Outlet_Details_master.Open_Time_placeholder')} value={outletinfo.opening_timings[day].open} label={this.props.t('dealerDetails.Outlet_Details_master.Open_Time')} onChange={this.handleInputchange(idx)} required={true}
                                                                                    error={outletinfo.error && outletinfo.error[day + '_open']} />}

                                                                                {/* <DatePicker className="form-control" showTimeSelect showTimeSelectOnly dateFormat="HH:mm" timeIntervals={30} selected={this.state.startDate} /> */}


                                                                            </div>
                                                                        </div>
                                                                        <div className="col-lg-3 col-md-3 col-sm-6">
                                                                            <div className="form-group mrg-B29">
                                                                                {/* <label>{this.props.t('dealerDetails.Outlet_Details_master.Close_Time')}</label> */}
                                                                                {/* <DatePicker
                                                            className="form-control"
                                                            selected={this.state.startDate}
                                                            onChange={this.handleChange}        
                                                        /> */}

                                                                                {<InputField className="form-control" type="text" name={day + `_close`} placeholder={this.props.t('dealerDetails.Outlet_Details_master.Close_Time_placeholder')} value={outletinfo.opening_timings[day].close} label={this.props.t('dealerDetails.Outlet_Details_master.Close_Time')} onChange={this.handleInputchange(idx)} required={true} error={outletinfo.error && outletinfo.error[day + '_close']} />}

                                                                            </div>
                                                                        </div>
                                                                        <div className="col-lg-4 col-md-4">
                                                                            <label className="switch-btn btn btn-link mrg-t25" htmlFor={outletinfo.id + '_onoff' + index}>
                                                                                <input className={'switch-btn switch-btn' + outletinfo.id} id={outletinfo.id + '_onoff' + index} value="on" name={day + `_on`} type="checkbox"

                                                                                    onClick={this.handleChange.bind(this)}
                                                                                    defaultChecked={(outletinfo.opening_timings[day].on == 'on') ? 'checked' : ''} />

                                                                                <div className="slider round"></div>
                                                                                <span className="switch-label">{this.props.t('dealerDetails.Outlet_Details_master.Open')}</span>
                                                                            </label>
                                                                        </div>
                                                                    </div>
                                                                </li>
                                                            )



                                                        }

                                                    </ul>
                                                </div>
                                                <div className="col-sm-6">
                                                    {/* <div className="map-wrap"></div> */}

                                                    <Map
                                                        //centerAroundCurrentLocation={true}
                                                        google={this.props.google}
                                                        style={style}
                                                        className={'map'}
                                                        center={{
                                                            lat: parseFloat(outletinfo.latitude),
                                                            lng: parseFloat(outletinfo.longitude)
                                                        }}
                                                        zoom={16}
                                                    >
                                                        <Marker
                                                            name={outletinfo.name}
                                                            position={{
                                                                lat: parseFloat(outletinfo.latitude),
                                                                lng: parseFloat(outletinfo.longitude)
                                                            }} />

                                                    </Map>

                                                </div>
                                            </div>


                                        </div>

                                        <div className="card-footer text-center">
                                            <Button btnClass="btn btn-primary" type="submit"> {this.props.t('dealerDetails.Outlet_Details_master.save')}</Button>
                                        </div>

                                    </div>

                                </form>
                            </Accordion>

                        )}

                    </div>



                    <div className="card-footer text-center">
                        {/* <Button btnClass="btn btn-default mrg-r30" ><i className="ic-arrow_back"></i> {this.props.t('dealerDetails.Outlet_Details_master.Previous')}</Button> */}
                        <NavLink to={this.props.prevpage} className="btn btn-default mrg-r30">{this.props.t('dealerDetails.Outlet_Details_master.Previous')} <i className="ic-arrow_back"></i></NavLink>
                        {/* <Button btnClass="btn btn-primary"> {this.props.t('dealerDetails.Outlet_Details_master.Next')} <i className="ic-arrow_forward"></i></Button> */}
                        <NavLink to={this.props.nextpage} className="btn btn-primary">{this.props.t('dealerDetails.Outlet_Details_master.Next')} <i className="ic-arrow_forward"></i></NavLink>
                    </div>
                </div>
            </div>

        )
    }
}

const mapStateToProps = state => {
    return {
        auth_token: state.authToken,
        user_loggedin: state.userLoggedIn,
        //dealer_id: state.dealer_id
    };
}

const mapDispatchToProps = dispatch => {
    return {
        updateStateIntoRedux: (actionType, stateData) => dispatch({ type: actionType, componentState: stateData })
    }
}

export default GoogleApiWrapper({
    apiKey: GOOGLE_MAP_API_KEY
})(withTranslation('dealers')(connect(mapStateToProps, mapDispatchToProps)(OutletDetails)));
