import React, { Component } from "react";
import Button from '../../elements/Button'
import MasterService from './../../../service/MasterService';
import { toast } from 'react-toastify';
import { withTranslation } from 'react-i18next';

class MakeClassified extends Component {
    constructor(props) {
        super(props);
        this.state = {
            carId: props.carId,
            classifiedVal:props.classifiedVal,
            maxlimit: false,
            currentIndex:props.currentIndex
        }
    }
    saveStockClassified = () => {        
            var thisObj = this;
            var postObj = { classified: this.state.classifiedVal, stock_used_car_id: this.state.carId };
            let updateJsonRow={};
            if(this.state.classifiedVal=='true'){
                //classifiedStatus=1;
                updateJsonRow.isclassified=1;
            }else{
                updateJsonRow.isclassified=0;
                updateJsonRow.ispremium=0;
            }
            MasterService.post('inventory/inventory/makeCarPremium', postObj)
                .then(function (response) {
                    thisObj.flag = false;
                    if (response.data.status == 200) {
                        toast.success(response.data.message);
                        if (typeof thisObj.props.updateStockData === 'function') {
                            thisObj.props.updateStockData(updateJsonRow, thisObj.state.currentIndex);
                          }
                        thisObj.props.modalClose();
                    }else{
                        toast.error(response.data.message);
                        thisObj.props.modalClose();
                    }
                })
                .catch(function (response) {
                });
        
    }
    render() {
       let maxclassifiedmsg=<p>{this.props.t('listInventory.mark_classified_msg')}</p>;
       if(this.state.classifiedVal=='false'){
            maxclassifiedmsg=<p>{this.props.t('listInventory.mark_unclassified_msg')}</p>;
       }
        if(this.state.maxlimit==true){
            maxclassifiedmsg= <div className="alert alert-danger">
            <p>
                <strong className="text-primary">{this.props.t('listInventory.classified_exceed_msg_1')}</strong>
            </p>
            <p>{this.props.t('listInventory.classified_exceed_msg_2')}</p>
        </div>
        }
        return (
            <div>
                {maxclassifiedmsg}
                <div className="col-sm-6 col-md-12 form-group text-right">
                    <Button type="button" onClick={this.saveStockClassified} colclass="col-sm-12" btnClass="btn btn-primary" name="addleadbtn" id="addleadbtn" title="Save" />
                </div>
            </div>
        );
    }
}

export default withTranslation('inventory')(MakeClassified);