import React, { Component } from 'react';
import Select from 'react-select';
import MasterService from './../../../service/MasterService';
import { withTranslation } from 'react-i18next';

class MmvDropDown extends Component {
    constructor(props) {
        super(props);        
        this.state = {
            model_list: [],
            make_list: [],
            version_list: [],
            car_make: props.defaultMake,
            car_model: '',            
            car_version: ''            
        };
        this.firstUpdate = true;
    }

    componentDidMount = () => {        
        this.__getMake();
    }

    handleMakeChange = (sname, ovalue) => {        
        this.setState({
            [sname]: ovalue.id,
            version_list: [],            
            car_model: '',            
            car_version: ''
        }, () => {
            this.__getModel(ovalue.id);
        });
        if (typeof this.props.handleOptionChange === 'function') {
            this.props.handleOptionChange(sname, ovalue);
        }
    }

    handleModelChange = (sname, ovalue) => {
        this.setState({
            [sname]: ovalue.id,                        
            car_version: ''
        },() => {
            this.__getVersion(ovalue.id);
        });
        if (typeof this.props.handleOptionChange === 'function') {
            this.props.handleOptionChange(sname, ovalue);
        }
    }

    handleVersionChange = (sname, ovalue) => {
        this.setState({
            [sname]: ovalue.vn_id
        });
        if (typeof this.props.handleOptionChange === 'function') {
            this.props.handleOptionChange(sname, ovalue);
        }
    }

    __getMake(){
        var parentObj = this;
        MasterService.get('core/commonservice/make_list')
        .then(function (response) {
            if(response.data.status == 200){
                parentObj.setState({ make_list: response.data.data});
            }            
        })
        .catch(function (response) {
        });
    }

    __getModel(make_id){
        var parentObj = this;
        MasterService.get('core/commonservice/model_list?make='+make_id)
        .then(function (response) {
            if(response.data.status == 200){
                parentObj.setState({ model_list: response.data.data });
            }
        })
        .catch(function (response) {
        });
    }

    __getVersion(model_id){
        var parentObj = this;
        MasterService.get('core/commonservice/version_list?model='+model_id)
        .then(function (response) {
            if(response.data.status == 200){
                parentObj.setState({ version_list: response.data.data });
            }
        })
        .catch(function (response) {
        });
    }

    componentWillReceiveProps(nextProps) {
        if(nextProps.defaultMake && this.firstUpdate){                          
            this.handleMakeChange('car_make',{id: nextProps.defaultMake}); 
            if(!nextProps.defaultModel){
                this.firstUpdate = false; 
            }         
        }
        if(nextProps.defaultModel && this.firstUpdate){      
                    
            this.handleModelChange('car_model',{id: parseInt(nextProps.defaultModel,10)});   
            if(!nextProps.defaultVersion){
                this.firstUpdate = false; 
            }     
        }
        if(nextProps.defaultVersion && this.firstUpdate){           
            
            this.handleVersionChange('car_version',{vn_id: nextProps.defaultVersion}); 
            //this.setState({car_version: nextProps.defaultVersion});    
            this.firstUpdate = false;         
        }         
    }  

    render() {
        let version_content = '';        
        if (this.props.showVersion === '1'){
            version_content = <div className={this.props.coloumnClass} >
                <Select
                    id="car_version"
                    value={this.state.car_version}
                    onChange={this.handleVersionChange.bind(this, 'car_version')}
                    options={this.state.version_list}
                    name="car_version"
                    placeholder= {this.props.t('listLead.Version')}
                    value={this.state.version_list.filter(({ vn_id }) => vn_id === this.state.car_version)}
                    getOptionLabel={({ vn }) => vn}
                    getOptionValue={({ vn_id }) => vn_id}
                />
            </div>
        }

        return (
            <div className="clearfix ">
              <label>{this.props.t('listLead.Select_A_Car')}</label>
                <div className="row">
                    <div className={this.props.coloumnClass}>
                        <Select
                            id="car_make"
                            value={this.state.car_make}
                            onChange={this.handleMakeChange.bind(this, 'car_make')}
                            options={this.state.make_list}
                            name="car_make"
                            placeholder= {this.props.t('listLead.Make')}
                            value={this.state.make_list.filter(({ id }) => id === this.state.car_make)}
                            getOptionLabel={({ make }) => make}
                            getOptionValue={({ id }) => id}
                        />
                    </div>
                    <div className={this.props.coloumnClass}>
                        <Select
                            id="car_model"
                            value={this.state.car_model}
                            onChange={this.handleModelChange.bind(this, 'car_model')}
                            options={this.state.model_list}
                            name="car_model"
                            placeholder= {this.props.t('listLead.Model')}
                            value={this.state.model_list.filter(({ id }) => id === this.state.car_model)}
                            getOptionLabel={({ m }) => m}
                            getOptionValue={({ id }) => id}
                        />
                    </div>
                    {version_content}                    
                </div>
            </div>
        );
    }
}

export default withTranslation('lead')(MmvDropDown);
