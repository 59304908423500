import React, { Component } from 'react';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Select from 'react-select';
import InputField from '../elements/Input';
import RadioBox from './../elements/Radiobox';
import CheckBox from './../elements/CheckBox';
import Button from '../elements/Button';
import Accordion from './../elements/Accordion';
const role = [
    { value: '125', label: 'Owner' },
    { value: '227', label: 'Dealer' },

];


class EmployeeDetails extends Component {

    constructor(props) {
        super(props);
        this.handleSelect = this.handleSelect.bind(this);
        this.handleChangetext = this.handleChangetext.bind(this);
        this.state = {
            key: 1,
            role: "",
            startDate: new Date()
        };
    }
    handleChange = date => {
        this.setState({
            startDate: date
        });
    };

    handleSelect(key) {
        this.setState({ key });
    }

    handleChange = (sname, ovalue) => {
        this.setState({
            [sname]: ovalue.value
        });

    }

    handleChangetext = (event) => {
        this.setState({
            [event.target.name]: event.target.value
        });
    }
    render() {
        return (
            <div><h1>Employee Details (Saroj Sahoo - GCD1069)</h1>
            <div className="card">
                <div className="card-heading"><h2 className="card-title">Employee Details</h2></div>
                <div className="card-body">
                    <Accordion title="Saroj Sahoo Role : Owner Contact No. 9910771125">
                        <div>
                            <div className="row">
                                <div className="col-sm-4"><InputField type="text" name="dealership_name" id="dealership_name" placeholder="Enter Dealership Name" value="Saroj Sahoo" label="Name*" /></div>
                                <div className="col-sm-4"><InputField type="email" name="dealership_email" id="dealership_email" placeholder="Enter Dealership Email" value="saroj.sahoo@girnarsoft.com" label="Email" /></div>
                                <div className="col-sm-4"><InputField type="password" name="dealership_password" id="dealership_password" placeholder="Password" value="9876543210" label="Password" /></div>
                            </div>
                            <div className="row">
                                <div className="col-sm-4">
                                    <label>Date of Birth</label>
                                    <div>
                                        <DatePicker
                                            className="form-control"
                                            selected={this.state.startDate}
                                            onChange={this.handleChange}
                                        />
                                    </div>
                                </div>
                                <div className="col-sm-4"><InputField type="tel" name="dealership_contact_number" id="dealership_contact_number" placeholder="Enter Dealership Mobile" value="9876543210" label="Dealership Contact No.*" /></div>
                                <div className="col-sm-4">
                                    <label>Role</label>
                                            <Select
                                                id="role"
                                                value={this.state.role}
                                                onChange={this.handleChange.bind(this, 'role')}
                                                options={role}
                                                name="role"
                                                placeholder="role"
                                                value={role.filter(({ value }) => value === this.state.role)}
                                                getOptionLabel={({ label }) => label}
                                                getOptionValue={({ value }) => value}
                                            />
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-sm-4">
                                    <label>Date of Joining</label>                                    
                                    <div>
                                        <DatePicker
                                            className="form-control"
                                            selected={this.state.startDate}
                                            onChange={this.handleChange}
                                        />
                                    </div>
                                </div>
                                <div className="col-sm-4">
                                    <label></label>
                                        <CheckBox type="checkbox" name="mapoutlet" id="mapoutlet" value="1" label="Map Outlet" />
                                </div>
                            </div>
                        </div>
                    </Accordion>
                    <Accordion title="Saroj Sahoo Role : Owner Contact No. 9910771125">
                        <div>
                            <div className="row">
                                <div className="col-sm-4"><InputField type="text" name="dealership_name" id="dealership_name" placeholder="Enter Dealership Name" value="Saroj Sahoo" label="Name*" /></div>
                                <div className="col-sm-4"><InputField type="email" name="dealership_email" id="dealership_email" placeholder="Enter Dealership Email" value="saroj.sahoo@girnarsoft.com" label="Email" /></div>
                                <div className="col-sm-4"><InputField type="password" name="dealership_password" id="dealership_password" placeholder="Password" value="9876543210" label="Password" /></div>
                            </div>
                            <div className="row">
                                <div className="col-sm-4">
                                    <label>Date of Birth</label>
                                    <div>
                                        <DatePicker
                                            className="form-control"
                                            selected={this.state.startDate}
                                            onChange={this.handleChange}
                                        />
                                    </div>
                                </div>
                                <div className="col-sm-4"><InputField type="tel" name="dealership_contact_number" id="dealership_contact_number" placeholder="Enter Dealership Mobile" value="9876543210" label="Dealership Contact No.*" /></div>
                                <div className="col-sm-4">
                                    <label>Role</label>
                                            <Select
                                                id="role"
                                                value={this.state.role}
                                                onChange={this.handleChange.bind(this, 'role')}
                                                options={role}
                                                name="role"
                                                placeholder="role"
                                                value={role.filter(({ value }) => value === this.state.role)}
                                                getOptionLabel={({ label }) => label}
                                                getOptionValue={({ value }) => value}
                                            />
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-sm-4">
                                    <label>Date of Joining</label>                                    
                                    <div>
                                        <DatePicker
                                            className="form-control"
                                            selected={this.state.startDate}
                                            onChange={this.handleChange}
                                        />
                                    </div>
                                </div>
                                <div className="col-sm-4">
                                    <label></label>
                                        <CheckBox type="checkbox" name="mapoutlet" id="mapoutlet" value="1" label="Map Outlet" />
                                </div>
                            </div>
                        </div>
                    </Accordion>
                    <Accordion title="Saroj Sahoo Role : Owner Contact No. 9910771125">
                        <div>
                            <div className="row">
                                <div className="col-sm-4"><InputField type="text" name="dealership_name" id="dealership_name" placeholder="Enter Dealership Name" value="Saroj Sahoo" label="Name*" /></div>
                                <div className="col-sm-4"><InputField type="email" name="dealership_email" id="dealership_email" placeholder="Enter Dealership Email" value="saroj.sahoo@girnarsoft.com" label="Email" /></div>
                                <div className="col-sm-4"><InputField type="password" name="dealership_password" id="dealership_password" placeholder="Password" value="9876543210" label="Password" /></div>
                            </div>
                            <div className="row">
                                <div className="col-sm-4">
                                    <label>Date of Birth</label>
                                    <div>
                                        <DatePicker
                                            className="form-control"
                                            selected={this.state.startDate}
                                            onChange={this.handleChange}
                                        />
                                    </div>
                                </div>
                                <div className="col-sm-4"><InputField type="tel" name="dealership_contact_number" id="dealership_contact_number" placeholder="Enter Dealership Mobile" value="9876543210" label="Dealership Contact No.*" /></div>
                                <div className="col-sm-4">
                                    <label>Role</label>
                                            <Select
                                                id="role"
                                                value={this.state.role}
                                                onChange={this.handleChange.bind(this, 'role')}
                                                options={role}
                                                name="role"
                                                placeholder="role"
                                                value={role.filter(({ value }) => value === this.state.role)}
                                                getOptionLabel={({ label }) => label}
                                                getOptionValue={({ value }) => value}
                                            />
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-sm-4">
                                    <label>Date of Joining</label>                                    
                                    <div>
                                        <DatePicker
                                            className="form-control"
                                            selected={this.state.startDate}
                                            onChange={this.handleChange}
                                        />
                                    </div>
                                </div>
                                <div className="col-sm-4">
                                    <label></label>
                                        <CheckBox type="checkbox" name="mapoutlet" id="mapoutlet" value="1" label="Map Outlet" />
                                </div>
                            </div>
                        </div>
                    </Accordion>
                    <Accordion title="Vinod Kumar Role : Owner Contact No. 9910771125">
                        <div>
                            <div className="row">
                                <div className="col-sm-4"><InputField type="text" name="dealership_name" id="dealership_name" placeholder="Enter Dealership Name" value="Saroj Sahoo" label="Name*" /></div>
                                <div className="col-sm-4"><InputField type="email" name="dealership_email" id="dealership_email" placeholder="Enter Dealership Email" value="saroj.sahoo@girnarsoft.com" label="Email" /></div>
                                <div className="col-sm-4"><InputField type="password" name="dealership_password" id="dealership_password" placeholder="Password" value="9876543210" label="Password" /></div>
                            </div>
                            <div className="row">
                                <div className="col-sm-4">
                                    <label>Date of Birth</label>
                                    <div>
                                        <DatePicker
                                            className="form-control"
                                            selected={this.state.startDate}
                                            onChange={this.handleChange}
                                        />
                                    </div>
                                </div>
                                <div className="col-sm-4"><InputField type="tel" className="form-control mobile" maxlength="10" name="dealership_contact_number" id="dealership_contact_number" placeholder="Enter Dealership Mobile" value="9876543210" label="Dealership Contact No.*" /></div>
                                <div className="col-sm-4">
                                    <label>Role</label>
                                            <Select
                                                id="role"
                                                value={this.state.role}
                                                onChange={this.handleChange.bind(this, 'role')}
                                                options={role}
                                                name="role"
                                                placeholder="role"
                                                value={role.filter(({ value }) => value === this.state.role)}
                                                getOptionLabel={({ label }) => label}
                                                getOptionValue={({ value }) => value}
                                            />
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-sm-4">
                                    <label>Date of Joining</label>                                    
                                    <div>
                                        <DatePicker
                                            className="form-control"
                                            selected={this.state.startDate}
                                            onChange={this.handleChange}
                                        />
                                    </div>
                                </div>
                                <div className="col-sm-4">
                                    <label></label>
                                        <CheckBox type="checkbox" name="mapoutlet" id="mapoutlet" value="1" label="Map Outlet" />
                                </div>
                            </div>
                        </div>
                    </Accordion>
                </div>

                <div className="card-footer text-center">
                        <Button btnClass="btn btn-default mrg-r30" ><i className="sprite ic-prev-b"></i> Previous</Button>
                        <Button btnClass="btn btn-primary"> Next <i className="sprite ic-next-w"></i></Button>
                    </div>
                </div>
            </div>

        )
    }
}

export default EmployeeDetails