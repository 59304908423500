import React, { Component } from 'react';
import { NavLink } from "react-router-dom";
import InputField from './../../elements/Input';
import RadioBox from './../../elements/Radiobox';
import CheckBox from './../../elements/CheckBox';
import Select from 'react-select';
import Button from './../../elements/Button';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { toast } from 'react-toastify';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import secureStorage from './../../../config/encrypt';
import { PASSWORD_VALIDATION, MOBILE_VALIDATION } from './../../../config/constant';
class BasicDetails extends Component {

    constructor(props) {
        super(props);
        this.state = {
            loading: (props.basic_details) ? false : true,
            basic_details: props.basic_details || {},
            owner_details: props.owner_details || {},
            dealership_list: props.dealership_list,
            // dealer_hash_id:props.dealer_hash_id || null,
            urlprefix: props.urlprefix || null,
            dealership_list_filtered: props.dealership_list[props.basic_details.parent_dealer_type],
            permission: props.permission || {},
            errors: {},
            responseMsg: null,
            startDate: new Date(),
            disabledpassword: (props.basic_details && props.basic_details.dealer_id_hash) ? true : false,
            getLanguagelist: props.getLanguagelist,
            dealerPremiumTypeList: props.dealerPremiumTypeList,
            EditFlag: (props.basic_details && props.basic_details.dealer_id_hash) ? true : false
        }

    }

    newPasswordEnable = (status) => {
        this.setState({ disabledpassword: status });
    }

    componentWillReceiveProps(nextProps) {
        this.setState({
            getLanguagelist: nextProps.getLanguagelist,
            dealerPremiumTypeList: nextProps.dealerPremiumTypeList,
            disabledpassword: (nextProps.basic_details && nextProps.basic_details.dealer_id_hash) ? true : false,
            loading: nextProps.loading,
            permission: nextProps.permission,
            basic_details: nextProps.basic_details,
            // dealer_hash_id: nextProps.dealer_hash_id,
            urlprefix: nextProps.urlprefix,
            owner_details: nextProps.owner_details,
            dealership_list: nextProps.dealership_list,
            responseMsg: nextProps.responseMsg,
            dealership_list_filtered: nextProps.dealership_list_filtered,
            EditFlag: (nextProps.basic_details && nextProps.basic_details.dealer_id_hash) ? true : false
        });
    }

    validForm = () => {
        let fields = this.state.basic_details;
        let fields_onwer = this.state.owner_details;
        let formIsValid = true;
        let errors = { ...this.state.errors };
        if (!fields["organization"]) {
            errors['organization'] = this.props.t('dealerDetails.Basic_Details_master.validation_texts.organization');
            formIsValid = false;
        }
        if (!fields["dealership_email"]) {
            errors['dealership_email'] = this.props.t('dealerDetails.Basic_Details_master.validation_texts.enter_valid_email');
            formIsValid = false;
        }
        if (!fields["dealership_contact"]) {
            errors['dealership_contact'] = this.props.t('dealerDetails.Basic_Details_master.validation_texts.enter_valid_phone')
            formIsValid = false;
        } else if (!MOBILE_VALIDATION.test(fields["dealership_contact"])) {
            formIsValid = false;
            errors["dealership_contact"] = this.props.t('dealerDetails.Basic_Details_master.validation_texts.enter_valid_phone');
        }
        if (!fields_onwer["name"]) {
            errors['name'] = this.props.t('dealerDetails.Basic_Details_master.validation_texts.owner_name');
            formIsValid = false;
        }
        if (!fields_onwer["email"]) {
            errors['email'] = this.props.t('dealerDetails.Basic_Details_master.validation_texts.enter_valid_email');
            formIsValid = false;
        }
        if (!fields_onwer["mobile"]) {
            errors['mobile'] = this.props.t('dealerDetails.Basic_Details_master.validation_texts.enter_valid_phone')
            formIsValid = false;
        } else if (!MOBILE_VALIDATION.test(fields_onwer["mobile"])) {
            formIsValid = false;
            errors["mobile"] = this.props.t('dealerDetails.Basic_Details_master.validation_texts.enter_valid_phone');
        }
        else if (fields_onwer["password"] && ('' + fields_onwer["password"]).match(PASSWORD_VALIDATION) == null) {
            formIsValid = false;
            errors["password"] = this.props.t('dealerDetails.Basic_Details_master.validation_texts.weak_password');
        }
        if (!fields["dealer_type_ids"] || fields["dealer_type_ids"].length == 0) {
            errors['dealer_type_ids'] = true;
            formIsValid = false;
        }

        this.setState({ errors: errors });
        return formIsValid;
    }

    saveBasicDetail = (event) => {
        event.preventDefault();
        if (this.validForm()) {
            if (typeof this.props.onClick === 'function') {
                this.props.onClick(event, this.state.owner_details, this.state.basic_details);
            }
        } else {
            toast.error(this.props.t('dealerDetails.Basic_Details_master.validation_texts.fill_required_fields'));
        }
    }

    handleLeadOptionChange = (sname, oValue) => {
        let basic_details = this.state.basic_details;
        this.setState({ basic_details });
        if (sname == 'premium_code') {
            basic_details['premium_code'] = oValue.code;
        } else {
            basic_details[sname] = (oValue.id) != null ? oValue.id : oValue.target.value;
        }

        this.setState({
            basic_details
        });
    }


    handleInputChange = (datefield, event) => {
        // alert(datefield);
        let basic_details = this.state.basic_details;
        let owner_details = this.state.owner_details;
        const errors = this.state.errors;
        //alert(event.target.name);
        event.target.name = datefield;
        if (event.target.name == 'organization') {
            basic_details['dealership_name'] = event.target.value;
        }
        if (event.target.name == 'name' || event.target.name == 'email' ||
            event.target.name == 'mobile' || event.target.name == 'password') {
            owner_details[event.target.name] = event.target.value;
        } else {
            basic_details[event.target.name] = event.target.value;
        }


        if (event.target.value != '') {
            if (event.target.name == 'email' || event.target.name == 'dealership_email') {
                let regexp = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
                if (regexp.test(event.target.value)) {
                    delete errors[event.target.name];
                } else {
                    errors[event.target.name] = this.props.t('dealerDetails.Basic_Details_master.validation_texts.enter_valid_email')
                }
            }
            else if (event.target.name == 'dealership_contact' || event.target.name == 'mobile') {
                if (!MOBILE_VALIDATION.test(event.target.value)) {
                    errors[event.target.name] = this.props.t('dealerDetails.Basic_Details_master.validation_texts.enter_valid_phone')
                } else {
                    delete errors[event.target.name];
                }

            }
            if (event.target.name == 'password') {
                let regexp = PASSWORD_VALIDATION;
                if (regexp.test(event.target.value)) {
                    delete errors[event.target.name];
                } else {
                    errors[event.target.name] = this.props.t('dealerDetails.Basic_Details_master.validation_texts.weak_password')
                }
            }

        } else if (event.target.name == 'password') {
            errors[event.target.name] = "";
        } else {
            errors[event.target.name] = this.props.t('dealerDetails.Basic_Details_master.validation_texts.please_enter') + event.target.name.replace("_", " ");
        }

        this.setState({ basic_details, errors, owner_details }, () => {
            //console.log(this.state.basic_details)
            //console.log(this.state.errors)
        });

    }

    handlechecked = (code) => event => {

        //let value = event.target.value;
        //alert(code);
        let value = code;
        let status = true;
        if (event.target.checked) {
            status = true;
        } else {
            status = false;
        }
        let classname = 'dealer_type_' + value;
        let chkbox = document.querySelectorAll('.' + classname).length;

        var basic_details_new2 = { ...this.state.basic_details }
        if (!basic_details_new2.dealer_type_ids) {
            basic_details_new2.dealer_type_ids = [];
        }

        for (let i = 0; i < chkbox; i++) {
            let idname = 'dealer_type_' + value + '_' + i;
            let elements = document.getElementById(idname);

            if (elements) {
                elements.checked = status;
                let chkval = parseInt(elements.value);
                if (status) {
                    if (!basic_details_new2.dealer_type_ids.includes(chkval)) {
                        basic_details_new2.dealer_type_ids.push(chkval);
                    }

                } else {
                    var index = basic_details_new2.dealer_type_ids.indexOf(chkval);
                    if (index !== -1) basic_details_new2.dealer_type_ids.splice(index, 1);
                }
            }

        }

        this.setState({ basic_details: basic_details_new2 })

        //this.setState({dealer_type_ids:dealer_type_ids})

    }

    handledate = (datefield, date) => {

        if (datefield == 'dealer_type_ids') {

            let chkval = parseInt(date.target.value);
            let errors = { ...this.state.errors };
            let basic_details = { ...this.state.basic_details };
            if (!basic_details.dealer_type_ids) {
                basic_details.dealer_type_ids = [];
            }
            if (!basic_details.dealer_type_ids.includes(chkval)) {
                // console.log('not');
                basic_details.dealer_type_ids.push(chkval);
            } else {
                // console.log('yes');
                let index_db = basic_details.dealer_type_ids.indexOf(chkval);
                basic_details.dealer_type_ids.splice(index_db, 1);
            }
            if (basic_details.dealer_type_ids.length == 0)
                errors['dealer_type_ids'] = this.props.t('dealerDetails.Basic_Details_master.validation_texts.this_field_required');
            else
                delete errors['dealer_type_ids'];

            console.log(basic_details.dealer_type_ids, "errors['dealer_type_ids']", errors['dealer_type_ids']);

            this.setState({ basic_details: basic_details, errors })

        } else if (datefield == 'parent_dealer_type') {
            let basic_details = { ...this.state.basic_details };
            let dealership_list = { ...this.state.dealership_list };
            let chkval = parseInt(date.target.value);
            let indexval = 0;
            if (chkval == 3) {
                indexval = 1;
            } else if (chkval == 4) {
                indexval = 2;
            }
            basic_details.dealer_type_ids = [];
            let dealership_list_filtered = dealership_list[indexval];
            basic_details.parent_dealer_type = chkval;
            this.setState({ dealership_list_filtered: dealership_list_filtered, basic_details: basic_details }, () => {
                // console.log(this.state.dealership_list_filtered)
            })
        } else {

            var owner_details = { ...this.state.owner_details }
            owner_details[datefield] = new Date(date);
            this.setState({ owner_details }, () => {
                // console.log(this.state.owner_details);
            })
        }
    }

    render() {
        let basicinfo={};
        let ownerinfo, dealership_list;

        basicinfo = this.state.basic_details;
        ownerinfo = this.state.owner_details;
        dealership_list = this.props.dealership_list;

        //console.log(basicinfo,'taaaaaaaa')

        let dealership_list_filtered = this.state.dealership_list_filtered;
        let dealershiptype_dealer = dealership_list[0];
        if (dealership_list_filtered) {
            //   console.log('dealershiptype_dealer_filter',dealershiptype_dealer)
            dealershiptype_dealer = dealership_list_filtered;
        }
        else {

            if (basicinfo.parent_dealer_type) {
                if (basicinfo.parent_dealer_type == '3' || basicinfo.parent_dealer_type == 3) {
                    dealershiptype_dealer = dealership_list[1];
                } else if (basicinfo.parent_dealer_type == '4' || basicinfo.parent_dealer_type == 4) {
                    dealershiptype_dealer = dealership_list[2];
                }
            }
        }

        if (!basicinfo.premium_code) {
            basicinfo.premium_code = 'nr';
        }
        let role;
        if (secureStorage.getItem('loginUserInfo')) {
            let loginResponse = secureStorage.getItem('loginUserInfo');
            if (loginResponse && loginResponse.data && loginResponse.data.user_data) {
                role = loginResponse.data.user_data.role;
            }
        }
        if (!basicinfo.dealer_type_ids && !this.state.EditFlag) {
            basicinfo.dealer_type_ids = [1];
        }
        let { errors, getLanguagelist, dealerPremiumTypeList } = this.state;
        return (

            <div>
                <h1>{this.props.t('dealerDetails.Basic_Details_master.Basic_Details')}
                    <span className="f12">
                        {
                            (basicinfo.gcd_code) ? <span> ({basicinfo.organization} - {basicinfo.gcd_code})</span> : ''
                        }
                    </span>

                </h1>
                <form className={(this.state.loading) ? 'text-left os-animation animated fadeInDown loading' : 'text-left os-animation animated fadeInDown'} data-os-animation="fadeInDown" data-os-animation-delay="0s" method="post" id="basicdetail_form" onSubmit={this.saveBasicDetail}>

                    <div className="card">
                        <div className="card-heading"><h2 className="card-title">{this.props.t('dealerDetails.Basic_Details_master.Basic_Details')}</h2></div>
                        <div className="card-body">
                            <div className="row">
                                <div className="col-sm-4">
                                    <InputField type="text" error={(errors.organization) ? errors.organization : ''} name="organization" id="dealership_name" placeholder={this.props.t('dealerDetails.Basic_Details_master.dealership_name_placeholder')} value={(this.state.basic_details.organization) ? this.state.basic_details.organization : ''} label={this.props.t('dealerDetails.Basic_Details_master.dealership_name_label')} onChange={this.handleInputChange.bind(this, 'organization')} required={true} />

                                </div>
                                <div className="col-sm-4">
                                    <InputField type="email" error={(errors.dealership_email) ? errors.dealership_email : ''} name="dealership_email" id="dealership_email" placeholder={this.props.t('dealerDetails.Basic_Details_master.dealership_email_placeholder')} value={(basicinfo.dealership_email) ? basicinfo.dealership_email : ''} label={this.props.t('dealerDetails.Basic_Details_master.dealership_email_label')} onChange={this.handleInputChange.bind(this, 'dealership_email')} required={true} />

                                </div>
                                <div className="col-sm-4">
                                    <InputField maxLength="12" type="tel" error={(errors.dealership_contact) ? errors.dealership_contact : ''} name="dealership_contact" id="dealership_contact_number" placeholder={this.props.t('dealerDetails.Basic_Details_master.dealership_contact_placeholder')} value={(basicinfo.dealership_contact) ? basicinfo.dealership_contact : ''} label={this.props.t('dealerDetails.Basic_Details_master.dealership_contact_label')} onChange={this.handleInputChange.bind(this, 'dealership_contact')} required={true} />
                                </div>
                            </div>
                            <div className="row">
                                <div className="form-field col-sm-4">
                                    <label>{this.props.t('dealerDetails.Basic_Employee_Details.language')}</label>
                                    <Select
                                        id="default_lang_id"
                                        options={getLanguagelist}
                                        onChange={this.handleLeadOptionChange.bind(this, 'default_lang_id')}
                                        name="default_lang_id"
                                        placeholder={this.props.t('dealerDetails.Basic_Employee_Details.language')}
                                        value={getLanguagelist.filter(({ id }) => id === basicinfo.default_lang_id)}
                                        getOptionLabel={({ name }) => name}
                                        getOptionValue={({ id }) => id}
                                    />
                                </div>
                                {
                                    (role == 'admin') ?
                                        <div className="form-field col-sm-4">
                                            <label>{this.props.t('dealerDetails.Basic_Employee_Details.premium_type')}</label>
                                            <Select
                                                id="premium_code"
                                                options={dealerPremiumTypeList}
                                                onChange={this.handleLeadOptionChange.bind(this, 'premium_code')}
                                                name="premium_code"
                                                placeholder={this.props.t('dealerDetails.Basic_Employee_Details.premium_type')}
                                                value={dealerPremiumTypeList.filter(({ code }) => code === basicinfo.premium_code)}
                                                getOptionLabel={({ title }) => title}
                                                getOptionValue={({ code }) => code}
                                            />
                                        </div> : ''
                                }
                            </div>

                            <div className="row" style={(this.state.permission.dealerTypeViewOnly == true) ? { pointerEvents: 'none' } : null}>
                                <div className="col-sm-4">
                                    <label>{this.props.t('dealerDetails.Basic_Details_master.dealership_type.label')} <span className="required" style={{ color: 'red' }}>*</span></label>
                                    <div className="rc-inline">
                                        <RadioBox type="radio" name="parent_dealer_type" id="dealer" value="1" label={this.props.t('dealerDetails.Basic_Details_master.dealership_type.dealer')} checked={(basicinfo.parent_dealer_type == 1 || (!basicinfo.parent_dealer_type)) ? true : false} onChange={this.handledate.bind(this, 'parent_dealer_type')} />
                                        <RadioBox type="radio" name="parent_dealer_type" id="dsa" value="3" label={this.props.t('dealerDetails.Basic_Details_master.dealership_type.dsa')} checked={(basicinfo.parent_dealer_type == 3) ? true : false} onChange={this.handledate.bind(this, 'parent_dealer_type')} />
                                        {/* <RadioBox type="radio" name="parent_dealer_type" id="proxyFinance" value="4" label={this.props.t('dealerDetails.Basic_Details_master.dealership_type.proxy_finance_dealer')} checked={(basicinfo.parent_dealer_type==4)?true:false} onChange={this.handledate.bind(this,'parent_dealer_type')}/>  */}
                                    </div>
                                </div>
                            </div>
                            {
                                (dealership_list.length > 0) ?

                                    <div className="alert alert-default form-group" style={(this.state.permission.dealerTypeViewOnly == true) ? { pointerEvents: 'none' } : null}>

                                        <div className="row">{dealershiptype_dealer.length}

                                            {
                                                (dealershiptype_dealer.dealer_types.length > 0) ?
                                                    dealershiptype_dealer.dealer_types.map((dealertype, k) =>
                                                        <div className="col-sm-6 " key={k + dealertype.name}>
                                                            <h6>{dealertype.name}</h6>
                                                            <div className="rc-inline">

                                                                {
                                                                    (dealertype.list.length > 0) ?

                                                                        <>

                                                                            <CheckBox type="checkbox" name="dealer_type[]" id={dealertype.code} value={dealertype.code} label={this.props.t('dealerDetails.Basic_Details_master.all')} data-key={dealertype.code} key={dealertype.code} onChange={this.handlechecked(dealertype.code)} />
                                                                            {dealertype.list.map((singletype, index) =>
                                                                                <CheckBox type="checkbox" name="dc_dealers_types_mapping[]" className={'dealer_type_' + dealertype.code}
                                                                                    id={'dealer_type_' + dealertype.code + '_' + index} value={singletype.id} label={singletype.name}
                                                                                    key={singletype.id} checked={(basicinfo.dealer_type_ids && basicinfo.dealer_type_ids.includes(singletype.id)) ? 'checked' : ''} onChange={this.handledate.bind(this, 'dealer_type_ids')}
                                                                                />
                                                                            )}
                                                                        </>


                                                                        : ''
                                                                }

                                                            </div>
                                                        </div>
                                                    ) : ''
                                            }

                                        </div>
                                    </div>

                                    : ''
                            }
                            {this.state.errors['dealer_type_ids'] &&
                                <div className="error show">{this.props.t('dealerDetails.Basic_Details_master.validation_texts.this_field_required')}</div>}

                            <h2 className="contentbreak">{this.props.t('dealerDetails.Basic_Details_master.owner_details')}</h2>
                            <div className="row">
                                <div className="col-sm-4">
                                    <InputField type="text" error={(errors.name) ? errors.name : ''} name="name" id="owner_name" placeholder={this.props.t('dealerDetails.Basic_Details_master.owner_name_placeholder')} value={ownerinfo.name} label={this.props.t('dealerDetails.Basic_Details_master.owner_name_label')} onChange={this.handleInputChange.bind(this, 'name')} required={true} />
                                </div>
                                <div className="col-sm-4">
                                    <InputField type="email" error={(errors.email) ? errors.email : ''} name="email" id="owner_email" placeholder={this.props.t('dealerDetails.Basic_Details_master.owner_placeholder')} value={ownerinfo.email} label={this.props.t('dealerDetails.Basic_Details_master.owner_email_label')} onChange={this.handleInputChange.bind(this, 'email')} required={true}/>
                                </div>
                                <div className="col-sm-4">
                                    <InputField type="tel" error={(errors.mobile) ? errors.mobile : ''} name="mobile" id="owner_contact_number" placeholder={this.props.t('dealerDetails.Basic_Details_master.owner_contact_number_placeholder')} value={ownerinfo.mobile} label={this.props.t('dealerDetails.Basic_Details_master.owner_contact_number_label')} onChange={this.handleInputChange.bind(this, 'mobile')} required={true} maxLength="12" />
                                </div>
                                <div className="col-sm-4 edit-mobile">
                                    <InputField type="password" disabled={this.state.disabledpassword} error={(errors.password) ? errors.password : ''} name="password" id="password" placeholder={this.props.t('dealerDetails.Basic_Details_master.owner_password_placeholder')} value={ownerinfo.password} label={this.props.t('dealerDetails.Basic_Details_master.owner_password_label')} onChange={this.handleInputChange.bind(this, 'password')}/>
                                    {(this.state.disabledpassword)
                                        ? <a className="edit-mobile-link" data-flag="false" onClick={this.newPasswordEnable.bind(this, false)}>{this.props.t('dealerDetails.Basic_Details_master.Enable_New_Password')}</a>
                                        : <a className="edit-mobile-link" data-flag="false" onClick={this.newPasswordEnable.bind(this, true)}>{this.props.t('dealerDetails.Basic_Details_master.Disabled_New_Password')}</a>}
                                </div>
                                {/* <div className="col-sm-4"><InputField type="text" name="dob" id="dob" placeholder="DOB" value={ownerinfo.birth_date} label="Date of Birth" onChange={this.handleChange}/></div> */}
                                <div className="col-sm-4">
                                    <label htmlFor="birth_date" className="">{this.props.t('dealerDetails.Basic_Details_master.owner_dateof_birth_label')}</label>
                                    <DatePicker showYearDropdown showMonthDropdown maxDate={new Date()} className="form-control" selected={(ownerinfo.birth_date) ? new Date(ownerinfo.birth_date) : ''} name="birth_date" id="birth_date" placeholder="DOB" label={this.props.t('dealerDetails.Basic_Details_master.owner_dateof_birth_placeholder')} onChange={this.handledate.bind(this, 'birth_date')} autoComplete="off" />
                                </div>
                                <div className="col-sm-4">
                                    <label htmlFor="date_of_joining" className="">{this.props.t('dealerDetails.Basic_Details_master.owner_dateof_joining_label')}</label>
                                    <DatePicker showYearDropdown showMonthDropdown className="form-control" selected={(ownerinfo.date_of_joining) ? new Date(ownerinfo.date_of_joining) : ''} name="date_of_joining" id="date_of_joining" placeholder={this.props.t('dealerDetails.Basic_Details_master.owner_dateof_joining_placeholder')} label={this.props.t('dealerDetails.Basic_Details_master.owner_dateof_joining_label')} onChange={this.handledate.bind(this, 'date_of_joining')} autoComplete="off" />
                                </div>

                            </div>
                        </div>

                        <div className="card-footer text-center">
                            <Button btnClass="btn btn-primary mrg-r30" title={this.props.t('dealerDetails.Basic_Details_master.save')} type="submit" />
                            {
                                (basicinfo && basicinfo.id && basicinfo.id > 0) ? <NavLink to={this.props.nextpage} className="btn btn-default">{this.props.t('dealerDetails.Outlet_Details_master.Next')} <i className="ic-arrow_forward"></i></NavLink> : ''
                            }

                        </div>
                    </div>
                </form>
            </div>

        )
    }
}

const mapStateToProps = state => {
    return {
        auth_token: state.authToken,
        user_loggedin: state.userLoggedIn,
        dealer_id: state.dealer_id
    };
}

const mapDispatchToProps = dispatch => {
    return {
        updateStateIntoRedux: (actionType, stateData) => dispatch({ type: actionType, componentState: stateData })
    }
}

export default withTranslation('dealers')(connect(mapStateToProps, mapDispatchToProps)(BasicDetails));