import React, { Component } from 'react';
import Noimage from '../../../webroot/images/noimage.jpg';
import CheckBox from '../../elements/CheckBox';
//import InputField from '../../elements/InputField';
import ModalPopup from '../../elements/Popup';
//import ShareDetails from './ShareDetails';
import AddBuyerLead from './Addbuyerlead';
import MakeFeatured from './MakeFeatured';
import AddToStock from './AddToStock';
import MakeClassified from './MakeClassified';
import DeleteReason from './DeleteReason';
//import EditListing from './EditListing';
import Carousel from './ListingCarousal';
//import ButtonGroup from '../../elements/ButtonGroup';
//import TagPhotos from './TagPhoto';
import ToolTip from './../../elements/Tooltip'
import { php } from 'locutus';
import MasterService from './../../../service/MasterService';
import { toast } from 'react-toastify';
import NumberFormat from 'react-number-format';
import {CONF_VAL} from './../../../config/constant';

import { Link } from 'react-router-dom';

import { withTranslation } from 'react-i18next';


class SearchResult extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showgallerymodal: false,
            showAddLeadPopup: false,
            showFeaturedPopup: false,
            showClassifiedPopup: false,
            showDeleteReasonPopup: false,
            showAddToStockPopup: false,
            removeReasonList: [],
            carouselSlidesData: [],
            carId: 0,
            currentIndex: 0,
            featuredVal: '',
            editPrice: {},
            updateCarPriceParams: {},            
            statusClassname: {
                'active': 'nav-item active',
                'removed': 'nav-item',
                'all': 'nav-item'
            }
        }
    }

    componentDidMount = () => {
        this.getStockRemoveReason();
    }

    getUsedCarImageList = async (stock_used_car_id) => {       
        var thisObj = this;
        var postObj = { stock_used_car_id: stock_used_car_id };
        MasterService.post('inventory/inventory/usedcarimagelist', postObj)
            .then(function (response) {                
                if (response.data.status == 200) {                    
                    let carImageListArr = [];
                    response.data.data.forEach(function (result) {
                        let imageUrl = result.url;
                        let tempCarImagedetail = {
                            content: <div><img src={imageUrl+'?tr=w-620,h-400'} alt="car-image" /></div>,
                            alt: 'Front',
                            src: imageUrl+'?tr=w-100,h-60'
                        }
                        carImageListArr.push(tempCarImagedetail);
                    });
                    thisObj.setState({
                        carouselSlidesData: carImageListArr
                    });
                }
            })
            .catch(function (response) {
            });

    }
    showModal = (type, carId, index) => {
        this.setState({ [type]: true, carId: carId, currentIndex: index });
    }
    showFeatureModal = (type, carId, featuredVal,classifiedVal, index) => {
        let updateFeature = '';
        if (featuredVal == 1) {
            updateFeature = 'false';
        } else if (featuredVal == 0) {
            updateFeature = 'true';
        }

        let updateClassified = '';
        if (classifiedVal == 1) {
            updateClassified = 'true';
        } else {
            updateClassified = 'false';
        }

        this.setState({ [type]: true, carId: carId, featuredVal: updateFeature,classifiedVal: updateClassified, currentIndex: index });
    }
    showClassifiedModal = (type, carId, e, index) => {
        let updateClassified = '';
        if (e.target.checked == true) {
            updateClassified = 'true';
        } else {
            updateClassified = 'false';
        }
        this.setState({ [type]: true, carId: carId, classifiedVal: updateClassified, currentIndex: index });
    }
    showgallaryModal = async (carId) => {
        await this.getUsedCarImageList(carId);
        this.setState({ showgallerymodal: true });
    }
    closeModal = () => {
        this.setState({ showgallerymodal: false, showAddLeadPopup: false, showFeaturedPopup: false, showClassifiedPopup: false, showDeleteReasonPopup: false, showAddToStockPopup: false });
    }

    showCarPrice = (id) => {

        let editPriceArr = { ...this.state.editPrice };
        Object.keys(editPriceArr).map(function (key, index) {
            editPriceArr[key] = 'hide';
        });
        editPriceArr['car_' + id] = 'show';

        this.setState({
            editPrice: {
                ...editPriceArr
            }
        }, () => {

        });
    }
    hideCarPrice = (id) => {        
        let editPriceArr = { ...this.state.editPrice };        
        this.setState({
            editPrice: {
                editPriceArr,
                ['car_' + id]: 'hide'
            }
        }, () => {

        });
    }
    changeCarPrice = (id, e) => {
        var replace = "["+CONF_VAL.CURRENCY_SEPARATOR+"]";        
        var re = new RegExp(replace,"g");
        
        this.setState({
            updateCarPriceParams: {
                stock_used_car_id: id,
                car_price: e.target.value.replace(re,'')
            }
        });
    }
    getStockRemoveReason() {        
        var thisObj = this;
        MasterService.get( 'core/commonservice/stock_remove_reason')
            .then(function (response) {
                if (response.data.status == 200) {
                    if (response.data.data.length) {
                        thisObj.setState({ removeReasonList: response.data.data });
                    }
                }
            })
            .catch(function (response) {
            });
    }

    saveUsedcarPrice = (id, index) => {
        console.log(this.state.updateCarPriceParams);
        if (this.state.updateCarPriceParams.stock_used_car_id == id && this.state.updateCarPriceParams.car_price > 10000000) {
            var postObj = { car_price: this.state.updateCarPriceParams.car_price, stock_used_car_id: this.state.updateCarPriceParams.stock_used_car_id };
            MasterService.post('inventory/inventory/updateusedstockcar', postObj)
                .then((response) => {
                    if (response.data.status == 200) {
                        toast.success(response.data.message);
                        this.updateListData({ car_price: this.state.updateCarPriceParams.car_price }, index);
                        this.hideCarPrice(id);
                    }
                    else{
                        toast.error(response.data.message);
                    }
                })
                .catch(function (response) {
                });
        } else {
            toast.error('Car price should greater than 10000000');
        }

    }

    statusClickHandler = (type, statusVal) => {
        this.props.handleStatusChange(statusVal);
        this.setState({
            statusClassname: {
                'active': 'nav-item',
                'removed': 'nav-item',
                'all': 'nav-item',
                [type]: 'nav-item active'
            }
        })
    }    

    handleRemovedCar = () =>{
        this.props.handleRemovedCar(this.state.currentIndex);
    }

    updateListData = (updatedObj, index) => {        
        this.props.updateListData(updatedObj, index);
    }

    __changeToRupiah(price){
        var rev     = parseInt(price, 10).toString().split("").reverse().join("");
        var rev2    = "";
        for(var i = 0; i < rev.length; i++){
            rev2  += rev[i];
            if((i + 1) % 3 === 0 && i !== (rev.length - 1)){
                rev2 += CONF_VAL.CURRENCY_SEPARATOR
            }
        }
        return CONF_VAL.CURRENCY_SYMBOL + rev2.split("").reverse().join("");
    }

    render() {        
        return (
            <div className="card">
                <div>
                    <ul className="list-inline nav-tabs">
                        <li className={this.state.statusClassname.active} onClick={() => { this.statusClickHandler('active', [1]) }}>
                            <div>
                                {this.props.t('listInventory.active_status')}<span>({this.props.statusCount.active})</span>
                            </div>
                        </li>
                        <li className={this.state.statusClassname.removed} onClick={() => { this.statusClickHandler('removed', [2]) }}>
                            <div>
                                {this.props.t('listInventory.removed_status')}<span>({this.props.statusCount.removed})</span>
                            </div>
                        </li>
                        <li className={this.state.statusClassname.all} onClick={() => { this.statusClickHandler('all', [1, 2]) }}>
                            <div>
                                {this.props.t('listInventory.all_status')}<span>({this.props.statusCount.all})</span>
                            </div>
                        </li>
                    </ul>
                </div>
                <div className="card-body">
                    <ul className="car-list">
                        {
                            this.props.stockData.length == 0 && this.props.firstLoad ? 
                                <li><h6 className="text-center text-danger text-bold">{this.props.t('listInventory.loading')}</h6></li> 
                                : this.props.stockData.length == 0 ? 
                                    <li><h6 className="text-center text-danger text-bold">{this.props.t('listInventory.no_record_found')}</h6></li> 
                                : ''
                        }
                        {this.props.stockData.map((car, index) =>
                            <li key={car.id}>
                                <div className="wrap row">
                                    <div className="col-sm-2">
                                        <div className="img-box">

                                            {car.car_profile_image ? (
                                                <a onClick={() => { this.showgallaryModal(car.id) }}>
                                                    <div>
                                                        <div className="img_thumb"><img src={car.car_profile_image+'?tr=w-215,h-160'} /></div>
                                                        <span className="link">{this.props.t('listInventory.view_photos')}</span>
                                                    </div>
                                                </a>
                                            ) : (
                                                    <Link to={`/add-stock/${car.car_id_hash}`}>
                                                        <div className="img_thumb"><img src={Noimage}></img></div>
                                                        {/* <span className="link">Click to upload</span> */}
                                                    </Link>
                                                )}

                                        </div>
                                    </div>
                                    <div className="col-sm-10">
                                        <div className="row">
                                            <div className="col-sm-6 col-md-7">
                                                <h2 className="carname">
                                                {car.car_status == '1'? 
                                                    <Link to={`/add-stock/${car.car_id_hash}`}>{car.make} {car.modelVersion}</Link>:<React.Fragment>{car.make} {car.modelVersion}</React.Fragment>
                                                }   
                                                </h2>
                                                <ul className="list-inline stock-listed">
                                                    <li>{car.reg_car_city_name}</li>
                                                    <li>{this.props.t('listInventory.listed_on')}: {car.listed_on}</li>
                                                </ul>

                                                <ul className="row car-specs">
                                                    <li className="col-xs-6 col-sm-6  col-md-4 col-lg-3 small"><i className="ic-cd-kilometer icons-normal"></i>{php.strings.number_format(car.km_driven)} {this.props.t('listInventory.km')}</li>
                                                    <li className="col-xs-6 col-sm-6 col-md-4 col-lg-3 small">
                                                        <i className="ic-cd-year icons-normal"></i>{car.display_reg_date}
                                                    </li>
                                                    {car.display_owner_type &&
                                                        <li className="col-xs-6 col-sm-6 col-md-4 col-lg-3 small"><i className="ic-cd-owner icons-normal"></i>{car.display_owner_type}</li>
                                                    }
                                                    <li className="col-xs-6 col-sm-6 col-md-4 col-lg-3 small"><i className="ic-cd-fuel icons-normal"></i>{car.fuel_type}</li>
                                                    {car.reg_no &&
                                                        <li className="col-xs-6 col-sm-6 col-md-4 col-lg-3 small"><i className="ic-rc-file icons-normal"></i>{car.reg_no}</li>
                                                    }

                                                    <li className="col-xs-6 col-sm-6 col-md-4 col-lg-3 small"><i className="ic-cd-Transmission icons-normal"></i>{car.transmission}</li>
                                                    <li className="col-xs-6 col-sm-6 col-md-4 col-lg-3 small"><i className="ic-colors icons-normal"></i>{car.uc_colour}</li>
                                                </ul>
                                            </div>
                                            <div className="col-sm-2 col-md-2 text-center">
                                                {car.leadCount == 0 ?<div className="lead-circle">
                                                    <div className="pie_progress__number">{car.leadCount}</div>
                                                    <div className="pie_progress__label">{this.props.t('listInventory.total_lead')}</div>
                                                    </div> :
                                                    <Link to={`/lead/${car.car_id_hash}`} className="lead-circle">
                                                    <div className="pie_progress__number">{car.leadCount}</div>
                                                    <div className="pie_progress__label">{this.props.t('listInventory.total_lead')}</div>
                                                    </Link>
                                                }                                                                                                    
                                            </div>
                                            <div className="col-sm-4 col-md-3">
                                                <div className="mrg-b15">
                                                    <strong>{this.props.t('listInventory.retail_price')}
                                                    <span className=" text-primary mrg-l5" id={`show_retail_price_${car.id}`} > {this.__changeToRupiah(car.car_price)}</span></strong>
                                                    <ToolTip  title={this.props.t('listInventory.edit')} ><span className="editBtn btn btn-link" onClick={() => { this.showCarPrice(car.id) }}><i className="ic-createmode_editedit"></i></span></ToolTip>
                                                    <div className={`editprice hide ${this.state.editPrice['car_' + car.id]}`}>
                                                        <div className="input-group form-group">
                                                            {/* <InputField
                                                                inputProps={{
                                                                    id: "edit_retail_price",
                                                                    type: "number",
                                                                    placeholder: this.props.t('listInventory.enter_price'),
                                                                    name: "price",
                                                                    autocompleate: "off",
                                                                }}
                                                                onChange={(e) => { this.changeCarPrice(car.id, e) }}
                                                            /> */}
                                                            <NumberFormat thousandSeparator={CONF_VAL.CURRENCY_SEPARATOR} decimalSeparator={CONF_VAL.CURRENCY_DECIMAL_SEPARATOR} maxLength="15"  name="edit_retail_price" onChange={(e) => { this.changeCarPrice(car.id, e) }}/>
                                                            <div className="input-group-append">
                                                                <button type="button" className="btn btn-primary" onClick={() => this.saveUsedcarPrice(car.id, index)}>{this.props.t('listInventory.save')}</button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                {car.car_status == '1' ?
                                                <div className="inlinecheck classifiedCheck">
                                                    <CheckBox
                                                        id="classified"
                                                        type="checkbox"
                                                        name="classified"
                                                        value="1"
                                                        label={this.props.t('listInventory.classified')}
                                                        checked={(car.isclassified == 1) ? true : false}
                                                        onChange={(e) => this.showClassifiedModal('showClassifiedPopup', car.id, e, index)}
                                                    />
                                                    <ToolTip id="ask" title={this.props.t('listInventory.'+CONF_VAL.STOCK_LIST_CLASSIFIED_TOOL_TIP)} ><i className="ic-error text-light"></i></ToolTip>
                                                    {/* <a className="classified-help-icon" data-trigger="hover" data-container="body" data-toggle="popover" data-placement="right" data-content="If you dont want to show the registration number to the customers you can keep " show="" on="" data-original-title="" title="">
                                                        <i className="sprite ic-help" aria-hidden="true"></i></a> */}
                                                </div> : ''}
                                                {car.show_weburl == 1 &&
                                                <div>
                                                    <span className="f12">{this.props.t('listInventory.view_on')}: </span>    
                                                    {Array.isArray(car.web_url)?
                                                        <>
                                                        {car.web_url.map((urlObj) => (
                                                            <a href={urlObj.url} key={urlObj.key} target="_blank" title={this.props.t('listInventory.website_url')} className="mrg-r5">
                                                                <i className={'source-icon '+ urlObj.css_class}></i>
                                                            </a>
                                                        ))}
                                                        </>
                                                        :
                                                        <a href={car.web_url} target="_blank" title={this.props.t('listInventory.website_url')} className="mrg-r5">
                                                            <i className="source-icon oto"></i>
                                                        </a>
                                                    }                                                                                                    
                                                </div>}
                                            </div>
                                            <div className="col-sm-12 actionbtn">
                                                <div className="btn-toolbar clearfix" data-gs-csstooltip-wrapper="tooltip">
                                                    {car.car_status == '1'? 
                                                        <div>
                                                            <div aria-label="First group" role="group" className="btn-group mrg-r10">
                                                            <Link to={`/add-stock/${car.car_id_hash}`}><button type="button" className="btn btn-default" title={this.props.t('listInventory.edit')}><i className="ic-createmode_editedit"></i></button></Link>
                                                            <button type="button" className="btn btn-default" onClick={() => this.showModal('showDeleteReasonPopup', car.id, index)} title={this.props.t('listInventory.remove')}><i className="ic-delete"></i></button>
                                                            </div>

                                                            <div aria-label="First group" role="group" className="btn-group mrg-r10">
                                                                <button type="button" className="btn btn-default" onClick={() => this.showFeatureModal('showFeaturedPopup', car.id, car.ispremium,car.isclassified, index)} title={car.ispremium == 0 ? this.props.t('listInventory.make_feature') : this.props.t('listInventory.un_feature')} ><i className= {car.ispremium == 0 ? "ic-cd-featured": "ic-cd-un-featured" }></i></button>
                                                            </div>

                                                            <div aria-label="First group" role="group" className="btn-group">
                                                                <button type="button" className="btn btn-default" onClick={() => this.showModal('showAddLeadPopup',car.id, index)} title={this.props.t('listInventory.add_lead')}><i className="ic-add"></i></button>
                                                            </div>
                                                        </div> : 
                                                        ''    
                                                    }    

                                                    {car.car_status == '2'? 
                                                        <div>
                                                            <div aria-label="First group" role="group" className="btn-group mrg-r10">
                                                                <button type="button" className="btn btn-default" onClick={() => this.showModal('showAddToStockPopup',car.id, index)} title={this.props.t('listInventory.add_to_stock')}><i className="ic-add"></i></button>
                                                            </div>
                                                        </div>: ''
                                                    }                                                
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </li>
                        )}
                    </ul>
                </div>

                {this.state.showAddLeadPopup == false ? '' :
                    <ModalPopup id="add_lead" className="modal" title={this.props.t('listInventory.add_buyer_lead')} modalClose={this.closeModal}>
                        <AddBuyerLead handleLeadAdd={(count) => this.props.handleLeadAdd(this.state.currentIndex, count)} modalClose={this.closeModal} carId={this.state.carId} />
                    </ModalPopup>
                }

                {this.state.showAddToStockPopup == false? '':
                    <ModalPopup id="add_to_stock" className="modal" title={this.props.t('listInventory.add_to_stock')} modalClose={this.closeModal}>
                        <AddToStock handleAddToStock={this.handleRemovedCar} modalClose={this.closeModal} carId={this.state.carId} />
                    </ModalPopup>
                }                    

                {this.state.showFeaturedPopup == false ? '' :
                    <ModalPopup id="featured_popup" className="modal" title={this.props.t('listInventory.add_feature')} modalClose={this.closeModal}>
                        <MakeFeatured modalClose={this.closeModal} carId={this.state.carId} featuredVal={this.state.featuredVal} classifiedVal={this.state.classifiedVal} updateStockData={this.updateListData} currentIndex={this.state.currentIndex} />
                    </ModalPopup>
                }
                {this.state.showClassifiedPopup == false ? '' :
                    <ModalPopup id="classified_popup" className="modal" title={this.props.t('listInventory.add_classified')} modalClose={this.closeModal}>
                        <MakeClassified modalClose={this.closeModal} carId={this.state.carId} classifiedVal={this.state.classifiedVal} updateStockData={this.updateListData} currentIndex={this.state.currentIndex} />
                    </ModalPopup>
                }
                {this.state.showDeleteReasonPopup == false ? '' :
                    <ModalPopup id="delete_reason_popup" className="modal" title={this.props.t('listInventory.select_remove_reason')} modalClose={this.closeModal}>
                        <DeleteReason handleRemovedCar={this.handleRemovedCar} modalClose={this.closeModal} removeReasonList={this.state.removeReasonList} carId={this.state.carId} />
                    </ModalPopup>
                }
                {this.state.showgallerymodal == false ? '' : <ModalPopup id="gallary" className="modal modal-lg" title={this.props.t('listInventory.view_photos')} modalClose={this.closeModal}>
                    {/* <ButtonGroup className="asd" /> */}
                    <Carousel slides={this.state.carouselSlidesData} id="abc" ></Carousel>
                    {/* <TagPhotos /> */}
                </ModalPopup>
                }
            </div>
        )
    }
}
export default withTranslation('inventory')(SearchResult);
