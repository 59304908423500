import React, { Component } from 'react';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import InputField from './../../elements/Input';
import CheckBox from './../../elements/CheckBox';
// import Rupees from './../../elements/Rupees';
import { toast } from 'react-toastify';
import { validate } from '@babel/types';
import NumberFormat from 'react-number-format';
import {CONF_VAL} from './../../../config/constant';
class SkuBox extends Component {

    constructor(props) {
        super(props);
        let sku = props.sku;
        //console.log(sku);
        if (!sku.b_details_sku_id) sku.b_details_sku_id = sku.master.id;
        if (!sku.b_details_org_validity_to) sku.b_details_org_validity_to = (Date.now() + parseFloat(sku.master.sku_validity) * (1000 * 60 * 60 * 24));
        if (sku.b_details_is_active == null) sku.b_details_is_active = '1';
        if (sku.b_details_sku_comment == null) sku.b_details_sku_comment = '';
        if (!sku.b_details_validity_from) sku.b_details_validity_from = Date.now();
        if (sku.b_details_selling_price==null) sku.b_details_selling_price = sku.master.sku_mrp;
        if (!sku.b_details_validity_to) sku.b_details_validity_to = (Date.now() + parseFloat(sku.master.sku_validity) * (1000 * 60 * 60 * 24));
        let fields_value = {};
        if (props.sku.fields && props.sku.fields.length) {
            props.sku.fields.map(v => {
                if (v.attr_attribute_code)
                    fields_value[`fields.${v.attr_attribute_code}`] = v.bd_attr_attribute_value
            })
        }
        // console.log("sku", sku)
        this.state = {
            ...sku,
            ...fields_value,
            fields: props.sku.fields || [],
            master: props.sku.master || {},
            errors: {},
            isOpen: props.isOpen ? true : false,
            type: "new", // 'new', 'existing', 'expired'
            isSubmitClick: false,
            b_details_selling_price: sku.b_details_selling_price,
            b_details_validity_from: sku.b_details_validity_from,
            b_details_validity_to: sku.b_details_validity_to
        };

    }
    ignore_codes = ['_start_date', '_end_date', '_selling_price', '_is_active']
    componentWillReceiveProps(nextProps) {
        // console.log("SKUBOX > componentWillReceiveProps() nextProps", nextProps)
        /* set sku subscription - END */
    }
    handelError(err) {
        console.error("_______________handelError() > ", err);
    }
    // onKeyPress = (e) => {
    //     console.log("onKeyPress = (e)", e);
    //     if (e.key === 'Enter') {
    //         this.validate()
    //         console.log('do validate');
    //         this.setState({ isSubmitClick: true });
    //     }
    // }
    validate = () => {
        let errors = {};
        let sku = {}
        let fields = [];

        // console.log(date2);
        if (!this.state.b_details_validity_to) errors.b_details_validity_to = `This field required`;
        if (!this.state.b_details_validity_from) errors.b_details_validity_from = `This field required`;
        if (!this.state.b_details_selling_price) errors.b_details_selling_price = `This field required`;

        if (this.state.fields && this.state.fields.length > 0) {
            this.state.fields.map(v => {
                if (!v.bd_attr_attribute_value) {
                    // console.log("validate() > error in v ", v);
                    errors.fields = errors.fields || {};
                    errors.fields[v.attr_attribute_code] = `This field required`;
                }
            })
        }
        console.log("errors", errors);
        this.setState({ errors });
        /*
            "fields": Joi.array().items(Joi.object().keys({
                "bd_attr_attribute_id": Joi.number().required(),
                "bd_attr_attribute_value": Joi.alternatives(Joi.string(), Joi.number()).required(),
                "attr_attribute_code": Joi.string().optional()
            }).optional()).optional(),*/
        /*attr_attribute_code:null
            b_details_dealer_id:114
            b_details_id:12691
            b_details_is_active:"0"
            b_details_org_validity_to:"2019-11-10T23:59:36.000Z"
            b_details_selling_price:10000
            b_details_sku_comment:""
            b_details_sku_id:20
            b_details_validity_from:"2019-11-10T23:59:36.000Z"
            b_details_validity_to:"2019-11-10T23:59:36.000Z"
            b_id:9099
            bd_attr_attribute_id:null
            bd_attr_attribute_value:null
            fields:[]
            sku_sku_type:"subscription"
            status_type:"expired"
            message:"success"
            status:200        */
    }
   
    get get_dealer_id_hash() {
        return this.props.dealer_id_hash
    }
    handleChange = (field) => (value) => {
        // console.log("handelChange() > before > ", {field, value});
        let other = {};
        if (value && value.target) {
            value = (value.target.type == 'checkbox') ? (value.target.checked ? '1' : '0') : value.target.value;
        }
        if (field.split(".").length > 0) {
            let field_name = field.split(".");
            if (field_name[0] == 'fields') {
                let m_attr = this.state.master.fields.filter(v => v.attr_attribute_code == field_name[1])[0]
                let idx = this.state.fields.findIndex(v => {
                    return (field_name[1] == v.attr_attribute_code)
                })
                // console.log("handleChange()", { idx, field, value }, "this.state.fields[idx]", this.state.fields[idx]);
                let row = this.state.fields[idx] || { attr_attribute_code: field_name[1], bd_attr_attribute_id: (m_attr && m_attr.attr_id) };
                row.bd_attr_attribute_value = value;
                let editData = this.state.fields;
                if (idx == -1) {
                    editData.push(row);
                }
                else {
                    editData = editData.map((v, k) => k === idx ? row : v);
                }
                other.fields = editData
                // this.setState({ fields: editData })
            }
        }
        else {
        }
        this.setState({ ...other, [field]: value }, (v1, v2) => {
            this.validate();
            // console.log("handelChange() > after > this.state > ", this.state, v1, v2);
            this.props.onChange({ ...this.state, master: this.state.master, errors: Object.keys(this.state.errors).length > 0 ? this.state.errors : null })
        });
    }
    title = (key) => {
        let attr = (this.state.master && this.state.master.fields) && this.state.master.fields.filter(v => v.attr_attribute_code == key)[0]
        return (attr && attr.attr_attribute_name) ? attr.attr_attribute_name : key;
    }
    componentDidMount() {
        // console.log("this.state.b_details_org_validity_to", this.state.b_details_org_validity_to);
        // this.setState({ dealer_id_hash: this.props.dealer_id_hash });
    }
    removeMe = () => {
        if (this.props.removeRow) {
            this.props.removeRow(this.state)
        }
        else {
            toast.error(`removeRow() not define.`);
        }
    }
    onSaveClick = () => {
        this.validate();
        // console.log("onSaveClick() CALL > this.state > ", this.state);
        this.props.onSaveClick && this.props.onSaveClick({ ...this.state, saveClick: true, master: this.state.master, errors: Object.keys(this.state.errors).length > 0 ? this.state.errors : null })
    }
    // conformPopup(e, row) {
    //     console.log("e", e.target);
    //     let msg = e.target.checked ? `Are you sure to subscribe ${row.master.sku_name}?` : `Are you sure to un-subscribe ${row.master.sku_name}?`
    //     return window.confirm(msg)
    // }

    render() {
        let { sku_name, sku_mrp } = this.state.master;
        let { errors } = this.state;
        let card_class;
        switch (this.props.card_type) {
            case 'create':
                card_class = "card-success";
                break;
            case 'existing':
                card_class = "card-success"; // "card-secondary"
                break;
            case 'expire':
                card_class = "card-danger";
                break;
            default:
                break;
        }
        let haveExtEndDate = false;     // DELETEME
        let showCode = (code) => (this.ignore_codes.indexOf(code) === -1)
        if (this.state.b_details_validity_from) this.state.b_details_validity_from = new Date(this.state.b_details_validity_from)
        if (this.state.b_details_validity_to) this.state.b_details_validity_to = new Date(this.state.b_details_validity_to)
        if (this.state.b_details_org_validity_to) this.state.b_details_org_validity_to = new Date(this.state.b_details_org_validity_to)
        const errorMsg = (code) => {
            if (!this.isSubmitClick) return;
            if (this.state.errors[code]) return this.state.errors[code];
        }
        /*  id:6
            sku_mrp:"9089.00"
            sku_msp:"6000.00"
            sku_name:"Featured Listing"
            sku_short_name:"featured_listing"
            sku_type:"service"*/
        return (
            <div className="col-sm-6 col-lg-4">
                <div className={`card ${card_class}`}>
                    <div className="card-heading">
                        <div className="row">
                            <div className="col-sm-8">
                                <h3 className="card-title">{sku_name} {this.state.b_id != null && `#${this.state.b_id}`} </h3>
                                <div>Price:&nbsp;
                                <NumberFormat prefix={CONF_VAL.CURRENCY_SYMBOL} displayType="text"  thousandSeparator="." decimalSeparator=","  value={this.state.b_details_selling_price} />
                                </div>
                            </div>
                            <div className="col-sm-4 text-right">
                                <div className="icons-normal">
                                    {!this.props.viewonly && <>
                                    <span className="hide"><i className="ic-refresh mrg-r15"></i></span>
                                    <span onClick={this.onSaveClick} className={(this.props.card_type == "create" || !this.state.isOpen) ? 'hide' : ''} ><i className="ic-save mrg-r15"></i></span>
                                    <span onClick={this.removeMe} className={this.props.card_type == "create" ? '' : 'hide'} ><i className="ic-delete mrg-r15"></i></span></>}
                                    <span onClick={() => this.setState({ isOpen: !this.state.isOpen })}>{this.state.isOpen ? <i className="ic-remove"></i> : <i className="ic-add"></i>}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    {this.state.isOpen &&
                        <div className="card-body" style={(this.props.viewonly) ? {pointerEvents: 'none'} : null}>
                            <div className="row">
                                <div className="col-sm-6 form-group">
                                    <label>{this.title("_start_date")}*</label>
                                    <div className={(errors.b_details_validity_from)?'error-field input-group':'input-group'}>
                                        {/* <div className="input-group-prepend"><span className="input-group-text"><i className="sprite ic-date"></i></span></div> */}
                                        <div className="form-field">
                                            <DatePicker
                                                className="form-control"
                                                selected={(this.state.b_details_validity_from)}
                                                onChange={this.handleChange("b_details_validity_from")}
                                                error="b_details_validity_from required"
                                                // minDate={new Date()}
                                                maxDate={this.state.b_details_validity_to}
                                                showYearDropdown
                                                showMonthDropdown
                                            />

                                        </div>
                                        {errors.b_details_validity_from &&
                                            <div class="error show" >{errors.b_details_validity_from}</div>
                                        }
                                    </div>
                                </div>
                                <div className="col-sm-6 form-group">
                                    <label>{this.title("_end_date")}*</label>
                                    <div className={(errors.b_details_validity_to)?'error-field input-group':'input-group'}>
                                        {/* <div className="input-group-prepend"><span className="input-group-text"><i className="sprite ic-date"></i></span></div> */}
                                        <div className="form-field">
                                            <DatePicker
                                                className="form-control"
                                                selected={(this.state.b_details_validity_to) ? this.state.b_details_validity_to : ''}
                                                onChange={this.handleChange("b_details_validity_to")}
                                                error={errorMsg('b_details_validity_to')}
                                                minDate={this.state.b_details_validity_from}
                                                showYearDropdown
                                                showMonthDropdown
                                            />

                                        </div>
                                        {errors.b_details_validity_to &&
                                            <div class="error show" >{errors.b_details_validity_to}</div>
                                        }
                                    </div>
                                </div>
                                {haveExtEndDate && <div className="col-sm-6 form-group">
                                    <label>{this.title("Ext End Date")}*</label>
                                    <div className="input-group">
                                        {/* <div className="input-group-prepend"><span className="input-group-text"><i className="sprite ic-date"></i></span></div> */}
                                        <div className="form-field">
                                            <DatePicker
                                                className="form-control"
                                                disabled="disabled"
                                                selected={this.state.b_details_org_validity_to}
                                                onChange={this.handleChange("b_details_org_validity_to")}
                                                error={errorMsg('b_details_org_validity_to')}
                                                showYearDropdown
                                                showMonthDropdown
                                            />
                                        </div>
                                    </div>
                                </div>}
                                <div className={(errors.b_details_selling_price)?'error-field col-sm-6 form-group':'col-sm-6 form-group'}>{/*className="rupees-field"*/}
                                    <div>Price:</div>
                                    <NumberFormat prefix={CONF_VAL.CURRENCY_SYMBOL} decimalScale="0" class="form-control" thousandSeparator="." decimalSeparator=","  value={this.state.b_details_selling_price} label={this.title("_selling_price")} name="b_details_selling_price" id="b_details_selling_price" onChange={this.handleChange("b_details_selling_price")}/>
                                    {errors.b_details_selling_price &&
                                        <div class="error show" >{errors.b_details_selling_price}</div>
                                    }
                                </div>
                                {this.state.master && this.state.master.fields && this.state.master.fields.map(row => <React.Fragment key={'fields-' + row.attr_attribute_code}>

                                    {(showCode(row.attr_attribute_code)) &&
                                        ((row.attr_attribute_type == 'integer') && <div className="col-sm-6">
                                            <InputField type="number"
                                                label={this.title(row.attr_attribute_code)}
                                                value={this.state[`fields.${row.attr_attribute_code}`]}
                                                onChange={this.handleChange(`fields.${row.attr_attribute_code}`)}
                                                name={this.state[`fields.${row.attr_attribute_code}`]} id={this.state[`fields.${row.attr_attribute_code}`]} ></InputField>
                                        </div>) ||
                                        ((row.attr_attribute_type == 'enum' && row.attr_selection_mode == 'multiple') && <div className="col-sm-4">
                                            <label>{this.title(row.attr_attribute_name)}</label>
                                            {row.fields && row.fields.map(row2 =>
                                                <CheckBox type="checkbox" label={this.title(row2.attr_attribute_name)}
                                                    checked={this.state[`fields.${row.attr_attribute_code}.${row2.attr_attribute_code}`]}
                                                    name={`fields.${row.attr_attribute_code}.${row2.attr_attribute_code}`}
                                                    id={`fields.${row.attr_attribute_code}.${row2.attr_attribute_code}`}
                                                    onChange={this.handleChange(`fields.${row.attr_attribute_code}.${row2.attr_attribute_code}`)} />
                                            )}
                                        </div>)
                                    }
                                </React.Fragment>)}
                                <div className="col-sm-12">
                                    <CheckBox checked={this.state.b_details_is_active == '1'} name="b_details_is_active" id="b_details_is_active"
                                        onChange={this.handleChange("b_details_is_active")} type="checkbox" label="Active" />
                                </div>
                                <div className="col-sm-12">
                                    <InputField value={this.state.b_details_sku_comment} name="b_details_sku_comment" id="b_details_sku_comment"
                                        onChange={this.handleChange("b_details_sku_comment")}
                                        type="text" label="Comments"></InputField>
                                </div>
                            </div>
                        </div>}
                </div>
            </div>
        )
    }
}

export default SkuBox