import React, { Component } from "react";
import Button from '../../elements/Button';
import MasterService from './../../../service/MasterService';
import { withTranslation } from 'react-i18next';
import { toast } from 'react-toastify';

class MakeFeatured extends Component {
    constructor(props) {
        super(props);
        this.state = {
            carId: props.carId,
            featuredVal:props.featuredVal,
            classifiedVal:props.classifiedVal,
            maxlimit: false,
            currentIndex:props.currentIndex
        }
    }
    saveReasonHandler = () => {            
            var thisObj = this;
            var postObj = { featuted: this.state.featuredVal, stock_used_car_id: this.state.carId };
            var premiumStatus=0;
            var is_classified=0;
            if(this.state.classifiedVal=='true'){
                is_classified=1;
            }
            if(this.state.featuredVal=='true'){
                premiumStatus=1;
                is_classified=1;
            }
            
            MasterService.post('inventory/inventory/makeCarPremium',postObj)
                .then(function (response) {
                    thisObj.flag = false;
                    if (response.data.status == 200) {
                        toast.success(response.data.message);
                        if (typeof thisObj.props.updateStockData === 'function') {
                            thisObj.props.updateStockData({ ispremium:premiumStatus,isclassified:is_classified }, thisObj.state.currentIndex);
                          }
                        thisObj.props.modalClose();
                    }
                    else{
                        toast.error(response.data.message);
                        thisObj.props.modalClose();
                    }
                })
                .catch(function (response) {
                });
        
    }
    render() {        
        let maxfeaturemsg= '';
        if(this.state.featuredVal == 'true'){
            maxfeaturemsg=<p>{this.props.t('listInventory.make_feature_txt')}</p>;
        }
        else{
            maxfeaturemsg=<p>{this.props.t('listInventory.make_unfeature_txt')}</p>;
        }
     
        if(this.state.maxlimit==true){
            maxfeaturemsg= <div className="alert alert-danger">
            <p>
                <strong className="text-primary">{this.props.t('listInventory.exceed_featured_limit_first_line')}</strong>
            </p>
            <p>{this.props.t('listInventory.exceed_featured_limit_second_line')}</p>
        </div>
        }else if(this.state.classifiedVal=='false'){
            maxfeaturemsg=  <div>{this.props.t('listInventory.not_classified_feature_msg')}</div>
        }
        return (
            <div>
                {maxfeaturemsg}
                <div className="col-sm-6 col-md-12 form-group text-right">
                    <Button type="button" onClick={this.saveReasonHandler} colclass="col-sm-12" btnClass="btn btn-primary" name="addleadbtn" id="addleadbtn" title="Save" />
                </div>
            </div>
        );
    }
}

export default withTranslation('inventory')(MakeFeatured);