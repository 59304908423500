import React, { Component } from 'react';

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import Select from 'react-select';
import InputField from '../elements/Input';
import RadioBox from './../elements/Radiobox';
import CheckBox from './../elements/CheckBox';
import Button from '../elements/Button';

const dealership = [
    { value: '0', label: 'Select Dealership Type' },
    { value: 'sole_proprietorship', label: 'Sole proprietorship' },
    { value: 'pvt_ltd', label: 'Pvt.ltd.' },
    { value: 'partnership', label: 'Partnership' },
    { value: 'others', label: 'Others' },
];

const city = [
    { value: '125', label: 'New Delhi' },
    { value: '227', label: 'Bangalore' },
    { value: '336', label: 'Mumbai' },
    { value: '471', label: 'Chennai' },
    { value: '344', label: 'Pune' },
    { value: '8', label: 'Hyderabad' },
    { value: '162', label: 'Gurgaon' }

];

const state = [
    { value: '125', label: 'Asam' },
    { value: '227', label: 'Bihar' },
    { value: '336', label: 'Haryana' },
    { value: '471', label: 'New Delhi' },

];

const business = [
    { value: '', label: 'Select Business Type' },
    { value: 'Proprietor Firm', label: 'Proprietor Firm' },
    { value: 'Partnership Firm', label: 'Partnership Firm' },
    { value: 'public_ltd', label: 'Private Ltd/Public Ltd Companies' },
];
const kyccategory = [
    { value: '', label: 'Select Category' },
    { value: '1', label: 'KYC' },
    { value: '2', label: 'PAN' },
    { value: '3', label: 'Cancelled Cheque' },
    { value: '4', label: 'Visiting Card' },
    { value: '5', label: 'Passport Photo' },
    { value: '6', label: 'Business Proof' },
    { value: '7', label: 'Other' },
    { value: '8', label: 'Names of Partners/Directors' },
    { value: '9', label: 'Partnership Deed' },
    { value: '10', label: 'MOA of company' },
    { value: '11', label: 'Board Resolution (BR) for authorization' },
];
const kycdoc = [
    { value: '', label: 'Select Document' },
    { value: '1', label: 'Driving License (Person 1)' },
    { value: '2', label: 'Driving License (Person 2)' },
    { value: '3', label: 'Driving License (Person 3)' },
    { value: '4', label: 'Driving License (Person 4)' },
    { value: '5', label: 'Driving License (Person 5)' },
    { value: '6', label: 'Passport (Person 1)' },
    { value: '7', label: 'Passport (Person 2)' },
    { value: '8', label: 'Passport (Person 3)' },
    { value: '9', label: 'Passport (Person 4)' },
    { value: '10', label: 'Passport (Person 5)' },
    { value: '11', label: 'Voter ID (Person 1)' },
    { value: '12', label: 'Voter ID (Person 2)' },
    { value: '13', label: 'Voter ID (Person 3)' },
    { value: '14', label: 'Voter ID (Person 4)' },
    { value: '15', label: 'Voter ID (Person 5)' },
    { value: '16', label: 'Aadhaar Card (Person 1)' },
    { value: '17', label: 'Aadhaar Card (Person 2)' },
    { value: '18', label: 'Aadhaar Card (Person 3)' },
    { value: '19', label: 'Aadhaar Card (Person 4)' },
    { value: '20', label: 'Aadhaar Card (Person 5)' },
];


class BasicDetails extends Component {

    constructor(props) {
        super(props);
        this.handleSelect = this.handleSelect.bind(this);
        this.handleChangetext = this.handleChangetext.bind(this);
        this.state = {
            key: 1,
            city: "",
            state: "",
            location: "",
            dealership:"",
            business:"",
            startDate: new Date()
        };
    }
    handleChange = date => {
        this.setState({
            startDate: date
        });
    };
    handleSelect(key) {
        this.setState({ key });
    }

    handleChange = (sname, ovalue) => {
        this.setState({
            [sname]: ovalue.value
        });

    }

    handleChangetext = (event) => {
        this.setState({
            [event.target.name]: event.target.value
        });
    }
    render() {
        return (
            <div><h1>KYC Details <span className="f12">(Saroj Sahoo - GCD1069)</span></h1>
            <div className="card">
                <div className="card-heading"><h2 className="card-title">Vendor Detail</h2></div>
                <div className="card-body">                    
                    <div className="row">
                        <div className="col-sm-4 form-group">
                            <label>Type of Dealership*</label>
                            <Select
                                id="dealership"
                                value={this.state.dealership}
                                onChange={this.handleChange.bind(this, 'dealership')}
                                options={dealership}
                                name="dealership"
                                placeholder="dealership"
                                value={dealership.filter(({ value }) => value === this.state.dealership)}
                                getOptionLabel={({ label }) => label}
                                getOptionValue={({ value }) => value}
                            />
                        </div>
                        <div className="col-sm-4"><InputField type="text" name="Vendor_name" id="Vendor_name" placeholder="Vendor Name" value="Vinod Kumar" label="Vendor Name*" /></div>
                        <div className="col-sm-4">
                            <InputField type="text" name="address1" id="address1" placeholder="Address Line 1*" value="" label="Address Line 1*" />
                        </div>
                        <div className="col-sm-4">
                            <InputField type="text" name="address2" id="address2" placeholder="Address Line 2" value="" label="Address Line 2" />
                        </div>
                        <div className="col-sm-4 form-group">
                            <label>State*</label>
                            <Select
                                id="state"
                                value={this.state.state}
                                onChange={this.handleChange.bind(this, 'state')}
                                options={state}
                                name="state"
                                placeholder="state"
                                value={state.filter(({ value }) => value === this.state.state)}
                                getOptionLabel={({ label }) => label}
                                getOptionValue={({ value }) => value}
                            />
                        </div>
                        <div className="col-sm-4 form-group">
                            <label>City*</label>
                            <Select
                                id="city"
                                value={this.state.city}
                                onChange={this.handleChange.bind(this, 'city')}
                                options={city}
                                name="city"
                                placeholder="City"
                                value={city.filter(({ value }) => value === this.state.city)}
                                getOptionLabel={({ label }) => label}
                                getOptionValue={({ value }) => value}
                            />
                        </div>
                        <div className="col-sm-4">
                            <InputField type="tel" name="pincode" id="pincode" placeholder="Pin Code" value="122003" label="Pincode*" />
                        </div>
                        <div className="col-sm-4"><InputField type="text" name="dealership_email" id="dealership_email" placeholder="Enter Dealership Email" value="saroj.sahoo@girnarsoft.com" label="Email*" /></div>
                        <div className="col-sm-4"><InputField type="tel" name="dealership_contact_number" id="dealership_contact_number" placeholder="Enter Dealership Mobile" value="9876543210" label=" Mobile No.*" /></div>
                        <div className="col-sm-4"><InputField type="tel" name="contact_number2" id="contact_number2" placeholder="Enter Dealership Mobile" value="9876543210" label=" Alternate Mobile No.*" /></div>
                        <div className="col-sm-4"><InputField type="tel" name="pancard" id="pancard" placeholder="Pan No" value="ABCD1234E" label=" Pan No." /></div>
                        <div className="col-sm-4"><InputField type="tel" name="gst" id="gst" placeholder="GST No" value="gst236578263576237" label="GST No." /></div>
                        <div className="col-sm-4 form-group">
                            <label>State in which GST Registered</label>
                            <Select
                                id="state"
                                value={this.state.state}
                                onChange={this.handleChange.bind(this, 'state')}
                                options={state}
                                name="state"
                                placeholder="state"
                                value={state.filter(({ value }) => value === this.state.state)}
                                getOptionLabel={({ label }) => label}
                                getOptionValue={({ value }) => value}
                            />
                        </div>
                    </div>
                    
                    <h2 className="contentbreak">Agreement Detail</h2>
                    <div className="row">
                        <div className="col-sm-3 form-group">
                            <label>Start Date</label>
                            <DatePicker
                                className="form-control"
                                selected={this.state.startDate}
                                onChange={this.handleChange}
                            />
                        </div>
                        <div className="col-sm-3 form-group">
                            <label>End Date</label>
                            <DatePicker
                                className="form-control"
                                selected={this.state.startDate}
                                onChange={this.handleChange}
                            />
                        </div>
                        <div className="col-sm-3 form-group">                            
                            <InputField type="file" name="UploadAgreement" id="UploadAgreement" placeholder="Upload Agreement" value="" label="Upload Agreement*" />
                        </div>
                        <div className="col-sm-3 form-group">
                        <label></label>
                            <div><Button type="button" btnClass="btn btn-link">VIEW UPLOADED FILE</Button></div>
                        </div>
                    </div>

                    <h2 className="contentbreak">Bank Detail</h2>
                    <div className="row">
                        <div className="col-sm-4"><InputField type="text" name="bankname" id="bankname" placeholder="Bank Name" value="AXIS Bank" label="Bank Name*" /></div>
                        <div className="col-sm-4"><InputField type="text" name="bankaddress1" id="bankaddress1" placeholder="Branch Address" value="Green Park" label="Branch Address line 1" /></div>
                        <div className="col-sm-4"><InputField type="text" name="bankaddress2" id="bankaddress2" placeholder="Branch Address" value="NEW DELHI" label="Branch Address line 2" /></div>
                        <div className="col-sm-4 form-group">
                            <label>State</label>
                            <Select
                                id="state"
                                value={this.state.state}
                                onChange={this.handleChange.bind(this, 'state')}
                                options={state}
                                name="state"
                                placeholder="state"
                                value={state.filter(({ value }) => value === this.state.state)}
                                getOptionLabel={({ label }) => label}
                                getOptionValue={({ value }) => value}
                            />
                        </div>
                        <div className="col-sm-4 form-group">
                            <label>Location of Bank(City)</label>
                            <Select
                                id="city"
                                value={this.state.city}
                                onChange={this.handleChange.bind(this, 'city')}
                                options={city}
                                name="city"
                                placeholder="City"
                                value={city.filter(({ value }) => value === this.state.city)}
                                getOptionLabel={({ label }) => label}
                                getOptionValue={({ value }) => value}
                            />
                        </div>
                        <div className="col-sm-4">
                            <InputField type="tel" name="pincode" id="pincode" placeholder="Pin Code" value="122003" label="Pincode" />
                        </div>
                        <div className="col-sm-4">
                            <InputField type="tel" name="acno" id="acno" placeholder="Bank Account Number" value="122003224242" label="Bank Account Number" />
                        </div>
                        <div className="col-sm-4">
                            <InputField type="text" name="micrcode" id="micrcode" placeholder="MICR Code Of Branch" value="122003" label="MICR Code Of Branch" />
                        </div>
                        <div className="col-sm-4">
                            <InputField type="text" name="ifsccode" id="ifsccode" placeholder="IFSC Code" value="AXIS122003" label="IFSC Code" />
                        </div>

                        <div className="col-sm-4 form-group">
                            <label>Business Type</label>
                            <Select
                                id="business"
                                value={this.state.business}
                                onChange={this.handleChange.bind(this, 'business')}
                                options={business}
                                name="business"
                                placeholder="business"
                                value={business.filter(({ value }) => value === this.state.business)}
                                getOptionLabel={({ label }) => label}
                                getOptionValue={({ value }) => value}
                            />
                        </div>
                    </div>

                    <h2 className="contentbreak">Upload Kyc Document</h2>
                    <p className="text-danger form-group f12">*Upload File Type should be png, jpg, jpeg, gif, pdf, doc, docx and  file size not large than 8mb</p>
                    <div className="row">
                        <div className="col-sm-3">
                        <label>Category</label>
                            <Select
                                id="kyccategory"
                                value={this.state.kyccategory}
                                onChange={this.handleChange.bind(this, 'kyccategory')}
                                options={kyccategory}
                                name="kyccategory"
                                placeholder="kyccategory"
                                value={kyccategory.filter(({ value }) => value === this.state.kyccategory)}
                                getOptionLabel={({ label }) => label}
                                getOptionValue={({ value }) => value}
                            />
                        </div>
                        <div className="col-sm-3">
                        <label>Document</label>
                            <Select
                                id="kycdoc"
                                value={this.state.kycdoc}
                                onChange={this.handleChange.bind(this, 'kycdoc')}
                                options={kycdoc}
                                name="kycdoc"
                                placeholder="kycdoc"
                                value={kycdoc.filter(({ value }) => value === this.state.kycdoc)}
                                getOptionLabel={({ label }) => label}
                                getOptionValue={({ value }) => value}
                            />
                        </div>
                        <div className="col-sm-3">
                            <InputField type="text" name="docnumber" id="docnumber" placeholder="Document Number" value="AXIS122003" label="Document Number" />
                        </div>
                        <div className="col-sm-3">
                            <InputField type="text" name="Comment" id="Comment" placeholder="Commente" value="" label="Comment" />
                        </div>

                        <div className="col-sm-3 form-group">
                            <div><Button type="button" btnClass="btn btn-link">VIEW UPLOADED Docs</Button></div>
                            
                        </div>
                        
                    </div>
                </div>
                <div className="card-footer text-center">
                    <div><Button type="button" btnClass="btn btn-primary">Save</Button></div>
                </div>
            </div>
            
        </div>

        )
    }
}

export default BasicDetails