import { toast } from 'react-toastify';
const AddStockValidation = {
    validFormData (formData,propsdata){
        let errors = {};
        let formIsValid = true;
        var numbers = /^[0-9]+$/;

        if (!formData['make_year']) {
            formIsValid = false;
            errors["make_year"] = propsdata.t('addInventory.validation_error.make_year_is_required');
        }
        if (!formData['version_id']) {
            formIsValid = false;
            errors["version_id"] = propsdata.t('addInventory.validation_error.Car_version_is_required');
        }
        if (!formData['km_driven']) {
            formIsValid = false;
            errors["km_driven"] = propsdata.t('addInventory.validation_error.Car_driven_km_is_required');
        } else if ((formData['km_driven'].toString()).match(numbers) == null) {
            formIsValid = false;
            errors["km_driven"] = propsdata.t('addInventory.validation_error.Car_driven_should_numeric_characters_only');
        }
        else if (formData['km_driven'] < 1000 || formData['km_driven'] > 1000000) {
            formIsValid = false;
            errors["km_driven"] = propsdata.t('addInventory.validation_error.Car_driven_should_less_than');
        }
        if (!formData['uc_colour_id'] && !formData['other_colour']) {
            formIsValid = false;
            errors["uc_colour_id"] = propsdata.t('addInventory.validation_error.Car_colour_is_required');
        }

        if (!formData['insurance_type']) {
            formIsValid = false;
            errors["insurance_type"] = propsdata.t('addInventory.validation_error.Insurance_type_is_required');
        }
        if (!formData['car_price']) {
            formIsValid = false;
            errors["car_price"] = propsdata.t('addInventory.validation_error.Car_price_is_required');
        } else if (formData['car_price'].toString().match(numbers) == null) {
            formIsValid = false;
            errors["car_price"] = propsdata.t('addInventory.validation_error.Car_price_should_numeric_characters_only');
        }
        // if (!formData['reg_valid_year']) {
        //     formIsValid = false;
        //     errors["reg_valid_year"] = "Number plate valid year is required.";
        // }
        if (!formIsValid) {
            toast.error(propsdata.t('addInventory.validation_error.fill_required_fields'));
        }
        let returnData={formIsValid:formIsValid,errors:errors};
        return returnData;
    }
    }
    export default AddStockValidation;