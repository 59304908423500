import React, { Component } from 'react';
import Select from 'react-select';
import DatePicker from "react-datepicker";
import { connect } from 'react-redux';
import InputField from './../../elements/InputField';
import { withTranslation } from 'react-i18next';
import "react-datepicker/dist/react-datepicker.css";

class SearchStock extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showAdvanceSearch: false,
      filterData: {
        today_work: 0,
        otp_verified: 0
      }
    };
  }

  handleOptionChange = (sname, ovalue) => {
    let filterData = this.state.filterData;
    filterData[sname] = (ovalue.value != null) ? ovalue.value : ovalue.target.value;
    this.setState({ filterData });
    if (typeof this.props.optionChange === 'function') {
      this.props.optionChange(sname, ovalue);
    }
  }

  handleAdvanceSearch = () => {
    this.setState({
      showAdvanceSearch: !(this.state.showAdvanceSearch) ? true : false
    })
  }

  handleDateChange = (date, sname) => {
    let filterData = this.state.filterData;
    filterData[sname] = date;
    this.setState({ filterData });
    if (typeof this.props.dateChange === 'function') {
      this.props.dateChange(date, sname);
    }
  }

  submitFilterForm = async(event) => {
    event.preventDefault();
    this.setState({loaderClass : 'loading'});
    if (typeof this.props.onSubmitFilter === 'function') {
      await this.props.onSubmitFilter(event);
      this.setState({loaderClass : ''});
    }
  }

  handelCheckbox = (event) => {
    let filterData = this.state.filterData;
    let updatedValue = (event.target.value == 0) ? 1 : 0;
    filterData[event.target.name] = updatedValue
    this.setState({ filterData });
    if (typeof this.props.optionChange === 'function') {
      let data = { value: updatedValue }
      this.props.optionChange(event.target.name, data);
    }
  }

  resetFilterForm = ()=>{
    this.setState({
      filterData: {
        today_work: 0,
        otp_verified: 0
      }
    });
    if (typeof this.props.resetFilter === 'function') {
      this.props.resetFilter();
    }
  }

  render() {
    const { statusList, sourceList } = this.props;
    const { filterData } = this.state;
    return (
      <div className="search-wrap">
        <div className="row">
          <div className="col-sm-6 col-md-2">
            <InputField
              inputProps={{
                id: "keyword",
                type: "text",
                placeholder: this.props.t('listLead.keyword_search'),
                name: "keyword",
                autocompleate: "off",
                label: this.props.t('listLead.keyword_search'),
                value: filterData.keyword || ''
              }}
              onChange={this.handleOptionChange.bind(this, 'keyword')}
            />
          </div>
          
          <div className="col-sm-6 col-md-2 form-group">
            <label>{this.props.t('listLead.status')}</label>
            <div className="row">
              <div className="col-xs-12">
                <Select
                  id="status"
                  value={filterData.status}
                  options={statusList}
                  onChange={this.handleOptionChange.bind(this, 'status')}
                  name="status"
                  placeholder={this.props.t('listLead.status')}
                  value={statusList.filter(({ value }) => value === filterData.status)}
                  getOptionLabel={({ value }) => value}
                  getOptionValue={({ key }) => key}
                />
              </div>
            </div>
          </div>
          <div className="col-sm-6 col-md-2 form-group">
            <label>{this.props.t('listLead.lead_created_date')}</label>
            <div className="row">
              <div className="col-xs-6">
                <DatePicker
                  selected={filterData.created_from}
                  onChange={(date) => this.handleDateChange(date, 'created_from')}
                  name="created_from"
                  id="created_from"
                  autocompleate="off"
                  placeholderText={this.props.t('listLead.From')}
                  dateFormat="yyyy-MM-dd"
                  className="form-control"
                  startDate={filterData.created_from}
                  endDate={filterData.created_to}
                  maxDate={filterData.created_to}
                />
              </div>
              <div className="col-xs-6">
                <DatePicker
                  selected={filterData.created_to}
                  onChange={(date) => this.handleDateChange(date, 'created_to')}
                  name="created_to"
                  id="created_to"
                  autocompleate="off"
                  placeholderText={this.props.t('listLead.To')}
                  dateFormat="yyyy-MM-dd"
                  className="form-control"
                  endDate={filterData.created_to}
                  minDate={filterData.created_from}
                />
              </div>
            </div>
          </div>
          <div className="col-sm-6 col-md-3 form-group">
              <label>{this.props.t('listLead.followup_date')}</label>
              <div className="row">
                <div className="col-xs-6">
                  <DatePicker
                    selected={filterData.followup_from}
                    onChange={(date) => this.handleDateChange(date, 'followup_from')}
                    name="followup_from"
                    id="followup_from"
                    autocompleate="off"
                    placeholderText="From"
                    dateFormat="yyyy-MM-dd"
                    className="form-control"
                    startDate={filterData.followup_from}
                    endDate={filterData.followup_to}
                    maxDate={filterData.followup_to}
                  />
                </div>
                <div className="col-xs-6">
                  <DatePicker
                    selected={filterData.followup_to}
                    onChange={(date) => this.handleDateChange(date, 'followup_to')}
                    name="followup_to"
                    id="followup_to"
                    autocompleate="off"
                    placeholderText="To"
                    dateFormat="yyyy-MM-dd"
                    className="form-control"
                    endDate={filterData.followup_to}
                    minDate={filterData.followup_from}
                  />
                </div>
              </div>
            </div>
          
          <div className="col-sm-6 col-md-3">
            <label></label>
            <div> <button type="submit" className={"btn btn-primary mrg-r10 " + this.state.loaderClass} onClick={this.submitFilterForm}>{this.props.t('listLead.search')}</button>
              <button type="reset" className="btn btn-default" onClick={this.resetFilterForm}>{this.props.t('listLead.reset')}</button>
            </div>
          </div>
        </div>
        {this.state.showAdvanceSearch ?
          <div className="row">
            <div className="col-sm-6 col-md-2 form-group">
            <label>{this.props.t('listLead.source')}</label>
            <div className="row">
              <div className="col-xs-12">
                <Select
                  id="lead_source"
                  value={filterData.lead_source}
                  options={sourceList}
                  onChange={this.handleOptionChange.bind(this, 'lead_source')}
                  name="lead_source"
                  placeholder={this.props.t('listLead.source')}
                  value={sourceList.filter(({ value }) => value === filterData.lead_source)}
                  getOptionLabel={({ value }) => value}
                  getOptionValue={({ key }) => key}
                />
              </div>
            </div>
          </div>
          <div className="col-sm-6 col-md-2 form-group">
            <label>{this.props.t('listLead.update_date')}</label>
            <div className="row">
              <div className="col-xs-6">
                <DatePicker
                  selected={filterData.updated_from}
                  onChange={(date) => this.handleDateChange(date, 'updated_from')}
                  name="updated_from" updated_from
                  id="updated_from"
                  autocompleate="off"
                  placeholderText={this.props.t('listLead.From')}
                  dateFormat="yyyy-MM-dd"
                  className="form-control"
                  startDate={filterData.updated_from}
                  endDate={filterData.updated_to}
                  maxDate={filterData.updated_to}
                />
              </div>
              <div className="col-xs-6">
                <DatePicker
                  selected={filterData.updated_to}
                  onChange={(date) => this.handleDateChange(date, 'updated_to')}
                  name="updated_to"
                  id="updated_to"
                  autocompleate="off"
                  placeholderText={this.props.t('listLead.To')}
                  dateFormat="yyyy-MM-dd"
                  className="form-control"
                  endDate={filterData.updated_to}
                  minDate={filterData.updated_from}
                />
              </div>
            </div>
          </div>
            <div className="col-sm-6 col-md-4 form-group">
              <label>{this.props.t('listLead.verified_by')}</label>
              <div className="row">
                <div className="col-sm-6 col-md-4 ">
                  <InputField
                    inputProps={{
                      id: "otp_verified",
                      type: "checkbox",
                      name: "otp_verified",
                      label: this.props.t('listLead.otp_verified'),
                      value: filterData['otp_verified']
                    }}
                    onChange={this.handelCheckbox}
                  />
                </div>
                <div className="col-sm-6 col-md-4 form-group">
                  <InputField
                    inputProps={{
                      id: "today_work",
                      type: "checkbox",
                      name: "today_work",
                      label: this.props.t('listLead.today_work'),
                      value: filterData['today_work']
                    }}
                    onChange={this.handelCheckbox}
                  />
                </div>
              </div>
            </div>
          </div>
          : ''}
        <label onClick={this.handleAdvanceSearch} className="advancesearch btn btn-link"><span>{this.state.showAdvanceSearch ? `- ${this.props.t('listLead.less_search')}` : `+${this.props.t('listLead.advance_search')}`}</span> </label>
      </div>
    )
  }
}
const mapStateToProps = state => {
  return {
    auth_token: state.authToken
  };
}
export default withTranslation('lead')(connect(mapStateToProps, null)(SearchStock));
