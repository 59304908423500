import React, { Component } from 'react';
import DateFormate from 'dateformat';
import Select from 'react-select';
import { Link } from 'react-router-dom';
import SearchFilter from './searchFilter';
import ToolTip from './../../elements/Tooltip';
import ModalPopup from './../../elements/Popup';
import CustomerTimeline from './CustomerTimeline';
import EditCustomerDetails from './EditCustomerDetails';
import EditRequirements from './EditRequirements';
import secureStorage from './../../../config/encrypt';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import TabCount from './tabCount';
import DatePicker from "react-datepicker";
import { toast } from 'react-toastify';
import MasterService from './../../../service/MasterService';
import LeadShare from './shareLead';
import "react-datepicker/dist/react-datepicker.css";
import qs from 'query-string';
import { APIV } from './../../../config/constant'

const ratingList = [
    { key: 'Hot', value: 'Hot' },
    { key: 'Cold', value: 'Cold' },
    { key: 'Warm', value: 'Warm' }
];

class BuyerEnquiry extends Component {
    constructor(props) {
        super(props);
        this.state = {
            hover: false,
            showmodal: false,
            showhistory: false,
            showCustDetailseditlead: false,
            showgallerymodal: false,
            showrow: false,
            showrowOption: '',
            sourceList: [],
            statusList: [],
            updateLeadData: {},
            filterData: {
                page_no: 0,
                type: 'all'
            },
            leadListData: [],
            errors: {},
            redirect: false,
            lead_id: '',
            preference: {},
            listFavCar: [],
            shareOption: false,
            leadShareData: {},
            loaderClass: '',
            pageLoading: true,
            loading: false,
            masterData : {}
        }
        this.onScroll = this.onScroll.bind(this);
        this.flag = true;
    }

    componentDidMount = async () => {
        await this.updateReduxData();
        await this.getFilterList();
        await this.getMakeList();
        let filterData = {...this.state.filterData};
        let preference = {...this.state.preference};
        preference['dealer_id'] = this.props.dealer_id;
        filterData['dealer_id'] = this.props.dealer_id;
        let car_id_hash = this.props.match.params.car_id_hash;
        let queryParams = this.props.location.search;
        if(queryParams){
            let queryObject = qs.parse(queryParams);
            for(let key in queryObject){
                filterData[key] = queryObject[key];
            }
        }
        filterData['car_id_hash'] = (car_id_hash) ? car_id_hash : '';
        this.setState({ preference: preference, filterData: filterData });
        await this.loadAllComponentData();
        this.onScroll('Mounter');
        window.addEventListener('scroll', this.onScroll);
    }
    componentWillReceiveProps = async (nextProps)=>{
        if(nextProps.location.state === 'loadAllLead'){
            await this.loadAllComponentData();
            this.resetFilter();
        }
    }

    getMakeList = async () => {
        await MasterService.get('core/commonservice/make_list').then((response) => {
            if (response.status == 200 && response.data.status == 200) {
                let masterData = {...this.state.masterData};
                masterData.makeList = response.data.data;
                this.setState({ masterData : masterData });
            }
        });
    }

    getFilterList = async () => {
        await MasterService.get('inventory/inventory/getfilter', null).then((response) => {
            if (response.status == 200 && response.data.status == 200) {
                let responseData = response.data.data;
                var masterData = {...this.state.masterData};
                responseData && responseData.map(v => {
                    if(v.key === 'body_type' || v.key === 'uc_transmission_id' || v.key === 'fuel_type_id'){
                        masterData[v.key] = v.list
                    }
                });
                this.setState({ masterData });
            }
        });
    }

    onScroll(event) {
        if ((window.innerHeight + window.scrollY > document.body.offsetHeight - 300)) {
            if (this.flag) {
                let current_page = this.state.filterData.page_no;
                let loading=false,pageloading=false;
                if(current_page==0) {
                    pageloading = true;
                }else{
                    loading = true;
                }
                this.setState({
                    filterData: {
                        ...this.state.filterData,
                        page_no: current_page + 1
                    },pageLoading: pageloading, loading: loading
                }, () => {
                    this.getLeadListingData();
                });
            }
            this.flag = false;
        }
    }

    getFavCarDetails = (fav_carIds) => {
        MasterService.post('lead/lead/getFavCarDetails', { fav_car_ids: fav_carIds, dealer_id: this.props.dealer_id })
            .then((response) => {
                if (response.data.status == 200) {
                    let favCar = { ...this.state.listFavCar };
                    response.data.data && response.data.data.forEach((cars) => {
                        favCar['car_' + cars.id] = cars;
                    });
                    this.setState({
                        listFavCar: favCar
                    });
                }
            });
    }

    getLeadListingData = async () => {
        var parentObj = this;
        MasterService.post('lead/lead/listLead', this.state.filterData)
        .then((response) => {
            parentObj.flag = false;
            if (response.status == 200 && response.data.status == 200) {
                let fav_carIds = [];
                response.data.data.lead_data.forEach(function (result) {
                    if (result.fav_car_id && !fav_carIds.includes(result.fav_car_id)) fav_carIds.push(result.fav_car_id);
                });
                parentObj.getFavCarDetails(fav_carIds);
                if (parentObj.state.filterData.page_no <= 1) {
                    let tabData = response.data.data.tabCount[0];
                    parentObj.setState({ leadListData: response.data.data.lead_data, tab_count: tabData, pageLoading: false, loading: false }, () => {
                        parentObj.flag = response.data.data.next_page;
                    });
                } else {
                    parentObj.setState({
                        leadListData: parentObj.state.leadListData.concat(response.data.data.lead_data), pageLoading: false, loading: false
                    }, () => {
                        parentObj.flag = response.data.data.next_page;
                    });
                }
            } else if (response.status == 401 && response.data.status == 401) {
                this.setState({ redirect: true, pageLoading: false, loading: false })
            }
        }).catch((error) => {

        })
    }

    submitFilterForm = () => {
        this.setState({pageLoading: true},()=>{
            this.getLeadListingData();
        });
    }

    loadAllComponentData = async () => {
        this.loadSourceList();
        this.loadStatusList();
    }

    loadStatusList = () => {
        var parentObj = this;
        MasterService.get('lead/lead/getAllStatus')
        .then((response)=> {
            if (response.status == 200 && response.data.status == 200) {
                parentObj.setState({
                    statusList: response.data.data
                })
            } else if (response.status == 401 && response.data.status == 401) {
                this.setState({ redirect: true })
            }
        }).catch((error)=> {

        });
    }

    loadSourceList = () => {
        var parentObj = this;
        MasterService.get('lead/lead/getAllSource')
            .then((response)=> {
                if (response.status == 200 && response.data.status == 200) {
                    parentObj.setState({
                        sourceList: response.data.data
                    });
                } else if (response.status == 401 && response.data.status == 401) {
                    this.setState({ redirect: true })
                }
            }).catch((error)=> {

            });
    }

    handleDateTimeChange = (key, date, sname) => {
        let leadListData = this.state.leadListData;
        leadListData[key][sname] = date;
        let inputDate = (APIV > 1) ? new Date(date) : DateFormate(new Date(date),"yyyy-mm-dd HH:MM:ss");
        leadListData[key][sname] = inputDate;
        if (leadListData[key]['lead_follow_date']) {
            document.getElementById('lead_follow_date' + key).classList.remove('show');
        } else {
            document.getElementById('lead_follow_date' + key).classList.add('show');
        }
        this.setState({ leadListData });
    }

    handleLeadOptionChange = (sname, leadIndex, oValue) => {
        let leadListData = this.state.leadListData;
        leadListData[leadIndex][sname] = (oValue.value) != null ? oValue.value : oValue.target.value;
        if(sname === 'status_name'){
            document.getElementById('status_name' + leadIndex).classList.remove('show');
        }
        this.setState({leadListData });
    }


    handleChangeEvent = event => {
        let leadListData = this.state.leadListData;
        let index = Number(event.target.attributes.getNamedItem('data-index').value);
        if (event.target.value) {
            leadListData[index][event.target.name] = event.target.value;
        }
        this.setState({
            leadListData
        });
    }

    updateLeadListingData = async (event) => {
        var parentObj = this;
        let { updateLeadData, leadListData } = this.state;
        var index = Number(event.target.attributes.getNamedItem('data-index').value);
        event.preventDefault();
        if (this.validForm(index)) {
            leadListData[index]['loaderClass']= 'loading';
            this.setState({ leadListData: leadListData })
            updateLeadData.dealer_id = this.props.dealer_id
            updateLeadData.lead_status = leadListData[index].status_name;
            updateLeadData.lead_rating = leadListData[index].lead_rating;
            updateLeadData.followup_date = leadListData[index].lead_follow_date;
            updateLeadData.reminder_date = leadListData[index].lead_reminder_date;
            updateLeadData.lead_comment = leadListData[index].lead_comment;
            updateLeadData.lead_id = leadListData[index].lead_id;
            MasterService.post('lead/lead/updateLead', updateLeadData)
            .then((response) => {
                parentObj.flag = false;
                if (response.status == 200 && response.data.status == 200) {
                    toast.success(response.data.message);
                    document.getElementById('lead_comment' + index).value = "";
                    parentObj.setDefaultPage();
                    leadListData[index]['loaderClass']= '';
                    this.setState({ leadListData: leadListData })
                } else if (response.status == 401 && response.data.status == 401) {
                    this.setState({ redirect: true })
                }
            }).catch((error) => {

            })
        }
    }

    validForm = (index) => {
        let leadListData = this.state.leadListData;
        let formIsValid = true;
        let currentDate = new Date();
        if(leadListData[index]["status_name"] === 'New'){
            document.getElementById('status_name' + index).innerHTML = 'Please change the status';
            document.getElementById('status_name' + index).classList.add('show');
            formIsValid = false;
        }else{
            document.getElementById('status_name' + index).classList.remove('show');
        }
        if (!leadListData[index]["lead_follow_date"]) {
            document.getElementById('lead_follow_date' + index).classList.add('show');
            formIsValid = false;
        }else if(currentDate > new Date(leadListData[index]["lead_follow_date"])){
            document.getElementById('lead_follow_date' + index).innerHTML = this.props.t('validation.future_date_only');
            document.getElementById('lead_follow_date' + index).classList.add('show');
            formIsValid = false;
        }
        return formIsValid;
    }

    updateReduxData = async () => {
        if (secureStorage.getItem('loginUserInfo')) {
            var loginResponse = secureStorage.getItem('loginUserInfo');
            this.props.updateStateIntoRedux('LOGIN_USER', loginResponse);
        } else {
            this.setState({ redirect: true })
        }
    }

    handleDateChange = (date, sname) => {
        let filterData = this.state.filterData;
        let inputDate = DateFormate(date, 'yyyy-mm-dd');
        filterData[sname] = inputDate;
        this.setState({
            filterData
        });
    }

    handleOptionChange = (sname, oValue) => {
        let filterData = this.state.filterData;
        if (sname === 'lead_source' || sname === 'status') {
            if (!filterData[sname]) filterData[sname] = [];
            filterData[sname][0] = oValue.key;
        } else {
            filterData[sname] = (oValue.value) != null ? oValue.value : oValue.target.value;
        }
        this.setState({
            filterData
        });
    }

    toggleHover() {
        this.setState({ hover: !this.state.hover })
    }
    showModal = () => {
        this.setState({ showmodal: true });
    }

    closeModal = () => {
        this.setState({ showmodal: false, showgallerymodal: false });
    }

    customerDetailsEditshowModal = (event) => {
        let editFormData = {};
        editFormData.lead_id = Number(event.target.attributes.getNamedItem('data-leadid').value);
        editFormData.mobile = event.target.attributes.getNamedItem('data-mobile').value;
        editFormData.email = event.target.attributes.getNamedItem('data-customeremail').value;
        editFormData.name = event.target.attributes.getNamedItem('data-customername').value;
        editFormData.alt_mobile_number = event.target.attributes.getNamedItem('data-alternatemobile').value;
        editFormData.location = Number(event.target.attributes.getNamedItem('data-location').value);
        this.setState({ showCustDetailseditlead: true, editFormData: editFormData });
    }
    customerDetailsEditCloseModal = () => {
        this.setState({ showCustDetailseditlead: false });
    }
    emailShareModalClose = () => {
        this.setState({ shareOption: false });
    }
    showHistoryModal = (event) => {
        var leadid = Number(event.target.attributes.getNamedItem('data-leadid').value);
        this.setState({ showhistory: true, lead_id: leadid });
    }
    historyCloseModal = () => {
        this.setState({ showhistory: false });
    }

    showgallaryModal = () => {
        this.setState({ showgallerymodal: true });
    }
    rowClose = (index) => {
        let leadListData = [...this.state.leadListData];
        leadListData[index]['show_option'] = 0;
        this.setState({ leadListData:leadListData });
    }
    showDetails = (event) => {
        let leadListData = [...this.state.leadListData];
        let lead_id = event.currentTarget.dataset.key;
        let clickedOpt = event.currentTarget.dataset.value;
        let dataIndex = event.currentTarget.dataset.index;
        var preference = { ...this.state.preference };
        let initialData = {};
        initialData.car_make = leadListData[dataIndex].make;
        initialData.car_model = leadListData[dataIndex].model;
        initialData.price_from = (leadListData[dataIndex].price_from) ? leadListData[dataIndex].price_from.toString() : '';
        initialData.dp_budget  = (leadListData[dataIndex].dp_budget) ? leadListData[dataIndex].dp_budget.toString() : '';
        initialData.fuel_type = (leadListData[dataIndex].fuel_type) ? Number(leadListData[dataIndex].fuel_type) : '';
        initialData.transmission = (leadListData[dataIndex].transmission_type) ? Number(leadListData[dataIndex].transmission_type) : '';
        initialData.body_type = (leadListData[dataIndex].body_type) ? Number(leadListData[dataIndex].body_type) : '';
        initialData.data_index = dataIndex;
        leadListData.map(data=>{
            return data['show_option'] = 0;
        });
        leadListData[dataIndex]['show_option'] = 1;
        if (clickedOpt === 'edit') {
            let dealer_id = preference.dealer_id;
            if (lead_id != preference.lead_id) {
                preference = {};
            }
            preference['dealer_id'] = dealer_id;
        }
        preference['lead_id'] = lead_id;
        preference['data_index'] = dataIndex;
        this.setState({leadListData:leadListData, preference: preference, showrow: true, showrowOption: clickedOpt, lead_id: lead_id, initialData: initialData });
    }
    setDefaultPage = () => {
        var parentObj = this;
        this.setState({
            filterData: {
                ...this.state.filterData,
                page_no: 1
            }
        }, () => {
            parentObj.getLeadListingData();
        });
    }
    handleTabClick = (clickedValue) => {
        var parentObj = this;
        this.setState({
            filterData: {
                ...this.state.filterData,
                type: clickedValue,
                page_no: 1
            },
            showrow: false
        }, () => {
            parentObj.getLeadListingData();
        });
    }

    updatePrefData = (field_key, field_value) => {
        let preference = { ...this.state.preference };
        if (field_value != null) {
            preference[field_key] = field_value;
        }
        this.setState({ preference });
    }

    updateLeadPref = async(event) => {
        event.preventDefault();
        var parentObj = this;
        var leadPrefData = this.state.preference;
        var initialData  = this.state.initialData;
        if (!leadPrefData.hasOwnProperty("body_type")) {
            leadPrefData['body_type'] = initialData['body_type'];
        }
        if (!leadPrefData.hasOwnProperty("budget")) {
            leadPrefData['budget'] = initialData['price_from'];
        }
        if (!leadPrefData.hasOwnProperty("dp_budget")) {
            leadPrefData['dp_budget'] = initialData['dp_budget'];
        }
        if (!leadPrefData.hasOwnProperty("model")) {
            leadPrefData['model'] = initialData['car_model'];
        }
        if (!leadPrefData.hasOwnProperty("make")) {
            leadPrefData['make'] = initialData['car_make'];
        }
        if (!leadPrefData.hasOwnProperty("fuel_type")) {
            leadPrefData['fuel_type'] = initialData['fuel_type'];
        }
        if (!leadPrefData.hasOwnProperty("transmission")) {
            leadPrefData['transmission'] = initialData['transmission'];
        }
        var data_index = leadPrefData['data_index'];
        MasterService.post('lead/lead/updatePreference', leadPrefData)
        .then((response) => {
            if (response.status == 200 && response.data.status == 200) {
                let leadListData = [...parentObj.state.leadListData];
                leadListData[data_index]['model'] = (leadPrefData['model']) ? leadPrefData['model'] : initialData['car_model'];
                leadListData[data_index]['make'] = (leadPrefData['make']) ? leadPrefData['make'] : initialData['car_make'];
                leadListData[data_index]['fuel_type'] = (leadPrefData['fuel_type']) ? leadPrefData['fuel_type'] : initialData['fuel_type'];
                leadListData[data_index]['transmission_type'] = (leadPrefData['transmission']) ? leadPrefData['transmission'] : initialData['transmission'];
                leadListData[data_index]['body_type'] = (leadPrefData['body_type']) ? leadPrefData['body_type'] : initialData['body_type'];
                leadListData[data_index]['price_from'] = (leadPrefData['budget']) ? leadPrefData['budget'] : initialData['price_from'];
                leadListData[data_index]['price_to'] = (leadPrefData['budget']) ? leadPrefData['budget'] : initialData['price_from'];
                parentObj.setState({ leadListData: leadListData },()=>{
                    this.setState(this.state);
                });
                toast.success(response.data.message);
            }
        }).catch((error) => {

        });
    }

    showSharePopup = (event)=>{
        let shareOption  = this.state.shareOption;
        let leadShareData= {...this.state.leadShareData};
        leadShareData.lead_email   = event.currentTarget.dataset.email;
        leadShareData.lead_mobile  = event.currentTarget.dataset.mobile;
        leadShareData.lead_id      = event.currentTarget.dataset.lead_id;
        this.setState({shareOption: (shareOption) ? false: true, leadShareData: leadShareData });
    }

    refreshLeadListData = ()=>{
        this.setState({
            pageLoading: true
        },()=>{
            this.getLeadListingData();
        });
    }

    resetFilter = ()=>{
        let filterData = {page_no: 1,type: 'all', dealer_id: this.props.dealer_id, car_id_hash: ""};
        this.setState({
            filterData:filterData, pageLoading: true
        },()=>{
            this.props.history.push('/lead');
            this.getLeadListingData();
        })
    }

    render() {
        // if (this.state.redirect) {
        //     return (<Redirect to={'/login'} />);
        // }
        const { leadListData, statusList, listFavCar } = this.state;
        const getValueBykey = (val, listName) => {
            if(this.state.masterData[listName] && this.state.masterData[listName].length > 0){
                let row = this.state.masterData[listName].filter(v => v.key == val)[0]
                return row && row.value;
            }
        }
        const getValueBykeyMake = (val, listName) => {
            if(this.state.masterData[listName] && this.state.masterData[listName].length > 0){
                let row = this.state.masterData[listName].filter(v => v.id == val)[0]
                return row && row.make;
            }
        }
        const getLocalityName = (locality_id)=>{
            if(this.props.dealer_locality && this.props.dealer_locality.length > 0){
                let locality = this.props.dealer_locality.filter(localityData=>localityData.id==locality_id)[0];
                return locality && locality.name;
            }
        }

        return (
            <div className={this.state.pageLoading ? "container-fluid manageStock loading" : "container-fluid manageStock"}>
                <div className="addStock">
                    <h1>{this.props.t('listLead.buyer_enquiry')}</h1>
                    <SearchFilter filterData={this.state.filterData} dateChange={this.handleDateChange} optionChange={this.handleOptionChange} statusList={this.state.statusList} sourceList={this.state.sourceList} onSubmitFilter={this.submitFilterForm} resetFilter={this.resetFilter} />
                    <div className="result-wrap">
                        <div className="card">
                            <div className="card-heading">
                                <div className="row">
                                    <div className="col-sm-6"><h2 className="card-title">{this.props.t('listLead.buyer_lead')}</h2></div>
                                    <div className="col-sm-6 text-right">
                                        <button type="button" name="refresh" id="refresh" className="btn btn-link mrg-r15" onClick={this.refreshLeadListData}>{this.props.t('listLead.refresh')}</button>
                                        {/* <button type="button" name="download" id="download" className="btn btn-link mrg-r15">{this.props.t('listLead.download_excel')}</button> */}
                                        <Link to="/add-lead"><button type="button" name="addlead" id="addlead" className="btn btn-success">{this.props.t('listLead.add_lead')}</button></Link>
                                    </div>
                                </div>
                            </div>
                            <div>
                                <TabCount tabData={this.state.tab_count} selectedTab={this.state.filterData.type} handleTabClick={this.handleTabClick} />
                                <div className="table-responsive">
                                    <table className="table table-bordered table-hover table-striped buyerenguiry-table">
                                        <thead>
                                            <tr>
                                                <th>{this.props.t('listLead.customer_detail')}</th>
                                                <th>{this.props.t('listLead.user_intrested')}</th>
                                                <th width="15%">{this.props.t('listLead.status')}</th>
                                                <th>{this.props.t('listLead.followup_date')}</th>
                                                <th>{this.props.t('listLead.comment')}</th>
                                                <th>{this.props.t('listLead.enquiry')}</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {leadListData.length > 0 ?
                                                leadListData.map((leadData, index) =>
                                                <React.Fragment  key={leadData.lead_id}>
                                                    
                                                        <tr>
                                                            <td>
                                                                <div id="customer_name_"><strong>{leadData.customer_name}</strong>
                                                                    <ToolTip title={leadData.lead_source}>
                                                                        <i className={leadData.lead_source.toUpperCase()+" source-icon"}></i>
                                                                    </ToolTip>
                                                                </div>
                                                                <div className="" id="customer_email_">{leadData.mobile}<br />{leadData.customer_email} <br />{getLocalityName(leadData.location_id)}</div>
                                                                <div className="listingdate">{DateFormate(leadData.lead_created_date, 'mmmm dd, yyyy h:MM TT')}</div>
                                                                <div>
                                                                    <a data-toggle="modal" data-leadid={leadData.lead_id} data-mobile={leadData.mobile} data-customeremail={leadData.customer_email} data-customername={leadData.customer_name} data-alternatemobile={leadData.customer_alt_mobile} data-location={leadData.location_id} className="btn btn-link" onClick={this.customerDetailsEditshowModal} >{this.props.t('listLead.Edit')}</a>
                                                                </div>
                                                            </td>
                                                            <td>
                                                                <div id="req_edit"><strong>{this.props.t('listLead.requirements')}:</strong><button className="btn btn-link pull-right" data-value="edit" data-index={index} data-key={leadData.lead_id} onClick={this.showDetails}>{this.props.t('listLead.Edit')}</button></div>
                                                                <div className="mrg-b15">
                                                                    <span id="req_budget"><i className="fa fa-inr" aria-hidden="true"></i>
                                                                        {[leadData.price_from, 
                                                                            getValueBykey(leadData.body_type, 'body_type'),
                                                                            getValueBykey(leadData.fuel_type, 'fuel_type_id'),
                                                                            getValueBykey(leadData.transmission_type, 'uc_transmission_id'),
                                                                            getValueBykeyMake(leadData.make, 'makeList')
                                                                        ].filter(v=>v).join(", ")}
                                                                    </span>
                                                                </div>
                                                                {listFavCar['car_' + leadData.fav_car_id]
                                                                    ?
                                                                    <div className="mrg-b15">
                                                                        <strong>{listFavCar['car_' + leadData.fav_car_id].make || 'N/A' + ' ' + listFavCar['car_' + leadData.fav_car_id].modelVersion || 'N/A'}</strong>
                                                                        <br />{listFavCar['car_' + leadData.fav_car_id].display_price || 'N/A'}, {listFavCar['car_' + leadData.fav_car_id].reg_no || 'N/A'}, {listFavCar['car_' + leadData.fav_car_id].reg_year || 'N/A'}, {listFavCar['car_' + leadData.fav_car_id].km_driven || 'N/A'} kms
                                                                    </div>
                                                                    : null
                                                                }
                                                                <div>
                                                                    <a data-toggle="modal" className="btn btn-link" data-value="details" data-index={index} data-key={leadData.lead_id} onClick={this.showDetails} >{this.props.t('listLead.View_Details')}</a>
                                                                </div>
                                                            </td>
                                                            <td>
                                                                <div className="form-group">
                                                                    <Select
                                                                        id="status_name"
                                                                        options={(leadData.lead_status_id !=13) ? statusList.filter(data=>data.id>=leadData.lead_status_id) : statusList}
                                                                        onChange={this.handleLeadOptionChange.bind(this, 'status_name', index)}
                                                                        name="status_name"
                                                                        placeholder= {this.props.t('listLead.status')}
                                                                        value={statusList.filter(({ value }) => value === leadData.status_name)}
                                                                        getOptionLabel={({ value }) => value}
                                                                        getOptionValue={({ key }) => key}
                                                                    />
                                                                    <span id={'status_name'+index} className="error"></span>
                                                                </div>
                                                                {leadData.status_name == 'Interested' ?
                                                                    <div>
                                                                        <Select
                                                                            id="lead_rating"
                                                                            options={ratingList}
                                                                            onChange={this.handleLeadOptionChange.bind(this, 'lead_rating', index)}
                                                                            name="lead_rating"
                                                                            placeholder= {this.props.t('listLead.Rating')}
                                                                            value={ratingList.filter(({ value }) => value === leadData.lead_rating)}
                                                                            getOptionLabel={({ value }) => value}
                                                                            getOptionValue={({ key }) => key}
                                                                        />
                                                                    </div>
                                                                    : null}
                                                            </td>
                                                            <td>
                                                                <DatePicker
                                                                    showTimeSelect
                                                                    selected={(leadData.lead_follow_date) ? new Date(leadData.lead_follow_date) : ''}
                                                                    onChange={(date) => this.handleDateTimeChange(index, date, 'lead_follow_date')}
                                                                    name="lead_follow_date"
                                                                    id="lead_follow_date"
                                                                    autocompleate="off"
                                                                    placeholderText={this.props.t('listLead.Followup_Date')}
                                                                    dateFormat="yyyy-MM-dd h:mm aa"
                                                                    className="form-control"
                                                                    minDate={new Date()}
                                                                />
                                                                <span id={'lead_follow_date' + index} className="error">{this.props.t('validation.followup_required')}</span>
                                                                <p></p>
                                                                {leadData.status_name == 'Walk-in Scheduled' ?
                                                                    <DatePicker
                                                                        showTimeSelect
                                                                        selected={(leadData.lead_reminder_date) ? new Date(leadData.lead_reminder_date) : ''}
                                                                        onChange={(date) => this.handleDateTimeChange(index, date, 'lead_reminder_date')}
                                                                        name="lead_reminder_date"
                                                                        id="lead_reminder_date"
                                                                        autocompleate="off"
                                                                        placeholderText={this.props.t('listLead.Set_Reminder')}
                                                                        dateFormat="yyyy-MM-dd h:mm aa"
                                                                        className="form-control"
                                                                        minDate={new Date()}
                                                                    />
                                                                    : null}
                                                            </td>
                                                            <td>
                                                                <div><strong>{this.props.t('listLead.comment')}:</strong>
                                                                    {leadData.comment ?
                                                                        leadData.comment
                                                                        :
                                                                        null}
                                                                </div>
                                                                <div className="listingdate">
                                                                    {leadData.latest_comment ?
                                                                        DateFormate(new Date(leadData.latest_comment), 'dd mmm yyyy h:MM TT')
                                                                        :
                                                                        null}
                                                                </div>

                                                                <div className="mrg-b15">
                                                                    <a data-toggle="modal" data-leadid={leadData.lead_id} className="btn btn-link" onClick={this.showHistoryModal} >{this.props.t('listLead.View_All')}</a>
                                                                </div>
                                                                <div>
                                                                    <textarea
                                                                        name="lead_comment"
                                                                        id={'lead_comment' + index}
                                                                        className="form-control"
                                                                        data-index={index}
                                                                        onChange={this.handleChangeEvent}
                                                                    ></textarea>
                                                                </div>
                                                            </td>
                                                            <td>
                                                                <div id={index+'_save_button'} className="mrg-b5"><button title="SAVE" data-index={index} onClick={this.updateLeadListingData} className={"btn btn-default updatelead " + leadData.loaderClass }>{this.props.t('listLead.Save')}</button></div>
                                                                <div id={index+'_share_button'}><button className="btn btn-default btn-sm" data-index={index} data-lead_id={leadData.lead_id} data-mobile={leadData.mobile} data-email={leadData.customer_email} onClick={this.showSharePopup}><i className="ic-share icons-normal" aria-hidden="true"></i></button> </div>
                                                            </td>
                                                        </tr>
                                                        {leadData.show_option == 1 ? 
                                                        <tr>
                                                            <td colSpan="12" className="pd-0"><EditRequirements showUpdateOption={this.state.showrowOption} updateLeadPref={this.updateLeadPref} updatePrefData={this.updatePrefData} rowClosefull={this.rowClose} lead_id={this.state.lead_id} initialData={this.state.initialData} /></td>
                                                        </tr>
                                                        : null
                                                        }
                                                    </React.Fragment>
                                                )
                                                :
                                                <tr>
                                                    <td colSpan="12" ><h6 className="text-danger">{this.props.t('listLead.no_lead_found')}</h6></td>
                                                </tr>
                                            }
                                            { (this.state.loading) ? <tr><td className="loading" colSpan="12"></td></tr> : null }
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {this.state.showhistory == false ? null : <ModalPopup id="EditBuyerDetails" className="modal " title={this.props.t('listLead.Customer_History')} modalClose={this.historyCloseModal}>
                    <CustomerTimeline lead_id={this.state.lead_id} />
                </ModalPopup>
                }
                {this.state.shareOption == false ? null : <ModalPopup id="shareEmailOption" className="modal " title="Share Lead" modalClose={this.emailShareModalClose}>
                    <LeadShare shareData={this.state.leadShareData} modalClose={this.emailShareModalClose} />
                </ModalPopup>
                }
                {this.state.showCustDetailseditlead == false ? null : <ModalPopup id="EditBuyerCustomerDetails" className="modal " title={this.props.t('listLead.Update_Customer_Details')} modalClose={this.customerDetailsEditCloseModal}>
                    <EditCustomerDetails editFormData={this.state.editFormData} cdcloseModal={this.customerDetailsEditCloseModal} setDefaultPage={this.setDefaultPage} />
                </ModalPopup>
                }
            </div>
        )
    }
}

const mapStateToProps = state => {
    return {
        auth_token: state.authToken,
        user_loggedin: state.userLoggedIn,
        dealer_id: state.dealer_id,
        dealer_locality: state.locality
    };
}

const mapDispatchToProps = dispatch => {
    return {
        updateStateIntoRedux: (actionType, stateData) => dispatch({ type: actionType, componentState: stateData })
    }
}
export default withTranslation('lead')(connect(mapStateToProps, mapDispatchToProps)(BuyerEnquiry));
