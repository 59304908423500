import React, { Component } from 'react';
import { BrowserRouter as Router, Route, Switch, Link } from "react-router-dom";
import { connect } from 'react-redux';
//Custom component
import PrivateRoute from './privateRoute';
import Header from './../view/common/Header';
import Footer from './../view/common/Footer';
import Login from './../view/login/component/login';
import AddStock from './../view/inventory/component/add-stock';
import ManageStock from './../view/inventory/component/manage-stock';
import Dashboard from '../view/dashboard/dashboard';
import BuyerLead from '../view/enquiry/component/BuyerEnquiry';
import AddbuyerEnquires from '../view/enquiry/component/AddbuyerEnquires';
import AdminRoute from './routeAdmin'
import EditListing from './../view/dealer/component/EditListing';
import MyAccount from './../view/dealerpages/MyAccount';
import AdminDealerList from './../view/dealer/component/dealer-list-foradmin';
import ForgotPassword from './../view/login/component/ForgotPassword';
import ResetPassword from './../view/login/component/ResetPassword';
import ChangePassword from './../view/login/component/ChangePassword';
import Logout from './../view/common/logout';
import NotFound from './../view/common/NotFound';

/* dealer detail */
import DealerDetail from './../view/dealerDetail/component/MyAccount';

class CustomRouter extends Component {
    constructor(props) {
        super(props);
        this.state = {ht:500}
    }
    componentDidMount(){
        this.setState({
            ht : window.innerHeight - 127
        });
        window.addEventListener('resize', () => {
            this.setState({
                ht : window.innerHeight - 127
            });
        }, false);
    }
    render() {
        const isLoggedIn = this.props.user_loggedin;
        return (
            <Router>
                {isLoggedIn == 1 ? <Header /> : ''}
                <div  style={{minHeight: this.state.ht}}>
                <Route path="/" exact component={Login} />
                <Route path="/login" component={Login} />
                <Route path="/forgot" component={ForgotPassword} />
                <Route path="/reset-password" component={ResetPassword} />
                <Route path="/change-password" component={ChangePassword} />
                <Route path="/dashboard" component={Dashboard} />
                <Route path="/add-stock" exact component={AddStock} />
                <Route path="/add-stock/:used_car_stock_id" component={AddStock} />
                <Route path="/manage-stock" component={ManageStock} />
                <PrivateRoute exact path="/add-lead" component={AddbuyerEnquires} />
                <PrivateRoute exact path="/lead/:car_id_hash?" component={BuyerLead} />
                <Route path="/edit-dealer-list" component={EditListing} />
                <Route path="/my-account" component={DealerDetail} />
                <Route path="/admin-dealer-list" component={AdminDealerList} />
                <Route path="/dealer/:dealer_id_hash" component={DealerDetail} />
                <Route path="/logout" component={Logout} />
                <Route path="/admin" component={AdminRoute} />
                </div>
                {isLoggedIn == 1 ? <Footer /> : ''}
            </Router>
        );
    }
}

const mapStateToProps = state => {
    return {
        user_loggedin: state.userLoggedIn
    };
}
export default connect(mapStateToProps, null)(CustomRouter);
