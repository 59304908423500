import MasterService from './MasterService';

const DealerService = {
    ...MasterService,
    getSkusDetails(dealer_id_hash) {
        return this.post('/dealer/subscription/list', { dealer_id_hash: dealer_id_hash })
    },
    saveSubscriptions(row, dealer_id_hash) {
        if (row.b_details_id == null) {
            row = {
                "b_details_sku_id": row.master.id,
                "b_details_validity_from": new Date(),
                "b_details_validity_to": new Date(),
                "b_details_org_validity_to": new Date(),
                "b_details_selling_price": row.master.sku_mrp,
                "b_details_is_active": row.b_details_is_active,
                "b_details_sku_comment": ""
            }
        }
        let data = {
            dealer_id_hash: dealer_id_hash,
            skus: [row]
        }
        console.log("saveSubscriptions() > row, dealer_id_hash---------", row, dealer_id_hash, "<<<<<<data>>>>>", data)
        if (row.b_details_id != null) {
            return this.post('/dealer/subscription/edit', data)
        }
        else {
            return this.post('/dealer/subscription/add', data)
        }
    },
    addBookingDetails(rows, dealer_id_hash) {
        console.log("addBookingDetails() > row, dealer_id_hash", rows, dealer_id_hash);
        if (!rows || !rows[0]) return Promise.resolve({ status: 200 })
        // // return this.getSkusDetails(dealer_id_hash);
        // if (row.b_details_id == null) {
        //     row = {
        //         "b_details_sku_id": row.master.id,
        //         "b_details_validity_from": new Date(),
        //         "b_details_validity_to": new Date(),
        //         "b_details_org_validity_to": new Date(),
        //         "b_details_selling_price": row.master.sku_mrp,
        //         "b_details_is_active": row.b_details_is_active,
        //         "b_details_sku_comment": ""
        //     }
        // }
        let data = {
            dealer_id_hash: dealer_id_hash,
            skus: rows
        }
        console.log("saveSubscriptions() > rows, dealer_id_hash---------", rows, dealer_id_hash, "<<<<<<data>>>>>", data)
        if (rows[0].b_details_id != null) {
            return this.post('/dealer/subscription/edit', data)
        }
        else {
            return this.post('/dealer/subscription/add', data)
        }
    },
    getSettingContacts(dealer_id_hash) {
        return this.get('/dealer/dealer/setting/contact', { dealer_id_hash: dealer_id_hash })
    },
    contactSettingSave(dealer_id_hash, _list) {
        return this.post('/dealer/dealer/setting/contact/save', { dealer_id_hash: dealer_id_hash, contact_settings: _list })
    },
    getEmployees(dealer_id_hash) {
        return this.post('/dealer/user-manager/list', { dealer_id_hash: dealer_id_hash })
    },
    saveKycDetails(dealerKycSaveJson) {
        return this.post('/dealer/dealer/kyc/save', dealerKycSaveJson)
    },
    saveKycDocDetails(dealerKycDocSaveJson) {
        return this.post('/dealer/dealer/kyc/doc/save', dealerKycDocSaveJson)
    },
    getKycDetails(dealer_id_hash) {
        return this.get('/dealer/dealer/kyc', { dealer_id_hash: dealer_id_hash })
    },
    getDocKycDetails(dealer_id_hash) {
        return this.get('/dealer/dealer/kyc/doc', { dealer_id_hash: dealer_id_hash })
    },
    getdashboardcard() {
        return this.get('/dealer/dealer/dashboard',{})
    },
    getdashboardleadcard(dashboardCountJson){
        return this.post('/lead/lead/get-dashboard-counts',dashboardCountJson) 
    },
    getBasicDetails(postdata){
        return this.post('/dealer/dealer/details',postdata) 
    },
    getDealerFormInfo(postdata){
        return this.post('/dealer/dealer/getDealerFormInfo',postdata) 
    },
    getOwnerDetails(postdata){
        return this.post('/dealer/user-manager/owner-detail',postdata) 
    },
    getDealertypeList(){
        return this.get('/dealer/dealer/master/dealer_type',{})
    },
    getKycMasterDetails(){
        return this.get('/dealer/dealer/master/kyc_master',{})
    },
    getOutletList(postdata){
        return this.post('/dealer/showroom/list',postdata) 
    },
    saveBasicinfo(postdata,method){
        return this[method]('/dealer/dealer/save_basic_info',postdata)
    },
    saveoutletInfo(postdata,save_outlet_url){
        return this.post('/'+save_outlet_url,postdata)
    }

}

export default DealerService;
