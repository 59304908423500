import allConf from "./config";
const conf = allConf[process.env.REACT_APP_CONFIG_FILE_NAME] ? allConf[process.env.REACT_APP_CONFIG_FILE_NAME] : allConf.ph; //allConf.demo;

export const API_URL = {
    // LEAD_API: "http://localhost:4020/",
    // INVENTORY_API: "http://localhost:4021/",
    // DEALER_API: "http://localhost:4022/",
    GETWAY_API: process.env.REACT_APP_GETWAY_API
    // GETWAY_API: "https://int-gcloud.gaadi.com/"
    // GETWAY_API: "http://192.168.72.36:8626/"
    // GETWAY_API: "http://localhost:8626/"
}
export const OWNER = [
    { value: '0', label: { 'en': '1st Owner', 'id': '1st Owner'} },
    { value: '1', label: { 'en': '2nd Owner', 'id': '2nd Owner' } },
    { value: '2', label: { 'en': '3rd Owner', 'id': '3rd Owner' } },
    { value: '3', label: { 'en': '4th Owner', 'id': '4th Owner' } },
    { value: '4', label: { 'en': 'Four +', 'id': 'Four +' } }
];

export const MONTH = [
    { value: 1, label: {en: 'Jan', id: 'Jan' }},
    { value: 2, label: {en: 'Feb', id: 'Feb' } },
    { value: 3, label: {en: 'Mar', id: 'Mar' } },
    { value: 4, label: {en: 'Apr', id: 'Apr' } },
    { value: 5, label: {en: 'May', id: 'May' } },
    { value: 6, label: {en: 'June', id: 'June' } },
    { value: 7, label: {en: 'July', id: 'July' } },
    { value: 8, label: {en: 'Aug', id: 'Aug' } },
    { value: 9, label: {en: 'Sep', id: 'Sep' } },
    { value: 10, label: {en: 'Oct', id: 'Oct' } },
    { value: 11, label: {en: 'Nov', id: 'Nov' } },
    { value: 12, label: {en: 'Dec', id: 'Dec' } }
];

export const APIV = 1;
export const SITE_CONTENTS = {
    DEALER_SUPPORT_EMAIL: conf.DEALER_SUPPORT_EMAIL,
    DEALER_SUPPORT_PHONE: conf.DEALER_SUPPORT_PHONE,
    SITE_NAME: conf.SITE_NAME,
    SITE_ADDRESS: conf.SITE_ADDRESS,
    SITE_COPYRIGHT:conf.SITE_COPYRIGHT,
};
export const LINK_URL = {
    LOGO: conf.LOGO,
    GOOGLE_PLAY: conf.GOOGLE_PLAY,
    APP_STORE: conf.APP_STORE,
    WHATSAPP_SHARE: conf.ALLOW_WHATSAPP_SHARE
};
export const CONFIG_FILE_CHANGE = {
    DP_BUDGET: conf.DP_BUDGET,
    FAV_ICON: conf.FAV_ICON
}
export const STOCK_PAGE_LIMIT = 100;
export const GOOGLE_MAP_API_KEY = process.env.REACT_APP_GOOGLE_MAP_API_KEY || "AIzaSyD4oX5QT9pbarorAGB6DTxZO4wt7u82hi0";
export const PASSWORD_VALIDATION=/^(?=.*\d)(?=.*[a-z])(?=.*[!@#$%^&*])(?=.*[A-Z])[0-9a-zA-Z!@#$%^&*]{6,}$/;
//export const MOBILE_VALIDATION=/^[8]\d{8,11}$/;
export const MOBILE_VALIDATION=new RegExp(conf.MOBILE_VALIDATION);
export const CONF_VAL =conf;


