import React, { Component } from "react";
import InputField from '../../elements/InputField'
import Select from 'react-select';
import Button from '../../elements/Button'
import { InventoryService } from './../../../service';
import { toast } from 'react-toastify';

class DeleteReason extends Component {
    constructor(props) {
        super(props);
        this.handleSelect = this.handleSelect.bind(this);
        this.handleChangetext = this.handleChangetext.bind(this);
        this.saveReasonHandler = this.saveReasonHandler.bind(this);
        this.state = {
            carId: props.carId,
            reason: ''
        }
    }

    handleSelect(key) {
        this.setState({ key });
    }

    handleChange = (sname, ovalue) => {
        this.setState({
            [sname]: ovalue.id
        });
    }

    handleChangetext = (event) => {
        this.setState({
            [event.target.name]: event.target.value
        });
    }

    saveReasonHandler = () => {
        if (this.state.reason) {
            var thisObj = this;
            var postObj = { stock_removal_reason_id: this.state.reason, stock_used_car_id: this.state.carId };
            if (this.state.reason == 1) {
                postObj.sold_price = this.state.sold_price;
            }

            InventoryService.deleteReason(postObj)
                .then(function (response) {
                    thisObj.flag = false;
                    if (response.data.status == 200) {
                        thisObj.props.modalClose();
                        thisObj.props.handleRemovedCar();
                        toast.success(response.data.message);
                    }
                    else{
                        toast.error(response.data.message);
                    }
                })
                .catch(function (response) {
                });
        }
    }

    // componentWillReceiveProps(nextProps) {
    // 	this.setState({ reasonList: nextProps.removeReasonList },()=>console.log('list',this.state));  
    // }

    render() {
        let disabledProp = '';
        if (!this.state.reason) {
            disabledProp = 'disabled';
        }

        return (
            <div>
                <div className="form-group">
                    <Select
                        id="reason"
                        value={this.state.reason}
                        onChange={this.handleChange.bind(this, 'reason')}
                        options={this.props.removeReasonList}
                        name="reason"
                        placeholder="Select a reason for car remove"
                        value={this.props.removeReasonList.filter(({ id }) => id === this.state.reason)}
                        getOptionLabel={({ reason }) => reason}
                        getOptionValue={({ id }) => id}
                    />
                    {this.state.reason != '1' ? '' :
                        <div>
                            <InputField
                                inputProps={{
                                    id: "sold_price",
                                    type: "text",
                                    placeholder: "Please enter selling price of car",
                                    name: "sold_price",
                                    autocompleate: "off",
                                    label: "Sold Price"
                                }}
                                onChange={this.handleChangetext}
                            />
                            <p className="text-left pad-T15">
                                We are developing a pricing index to help you sell your car faster by pricing it right, in order to do so we need the selling price of your car. The selling price is not saved against any dealership name or car id, this data is saved anonymously. Help us to serve you better.</p>
                        </div>
                    }
                </div>
                <div className="col-sm-6 col-md-12 form-group text-right">
                    <Button onClick={this.props.modalClose} colclass="col-sm-12" btnClass="btn btn-link mrg-r15" type="reset" name="addleadbtn" id="cancel" title="Cancel" />
                    <Button type="button" disabled={disabledProp} onClick={this.saveReasonHandler} colclass="col-sm-12" btnClass="btn btn-primary" name="addleadbtn" id="addleadbtn" title="Save" />
                </div>
            </div>
        );
    }
}

export default DeleteReason;